import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Chip, CircularProgress } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { formatCurrency } from '../utils/format';
import api from '../services/api';

const ConfirmacaoPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const { 
    fullName, 
    productTitle, 
    productPrice, 
    selectedOffers, 
    totalPrice,
    formData,
    payment_id,
    product: initialProduct 
  } = location.state || {};
  
  const transactionId = payment_id || 'N/A';

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}/`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Produto não encontrado');
      } finally {
        setIsLoading(false);
      }
    };

    if (initialProduct) {
      // If product data is available in navigation state, use it
      setProduct(initialProduct);
      setIsLoading(false);
    } else if (id) {
      // Otherwise fetch it if we have an ID
      fetchProduct();
    } else {
      setIsLoading(false);
    }
  }, [id, initialProduct]);

  if (isLoading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh',
        color: 'error.main'
      }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      maxWidth: 480, 
      margin: '0 auto', 
      padding: 2,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Header */}
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Box sx={{
          width: 80,
          height: 80,
          borderRadius: '50%',
          bgcolor: '#22c55e20',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto 24px'
        }}>
          <CheckCircleIcon sx={{ color: '#22c55e', fontSize: 40 }} />
        </Box>
        <Typography variant="h4" sx={{ fontWeight: 600, color: '#22c55e', mb: 2 }}>
          Pagamento aprovado
        </Typography>
      </Box>

      {/* Payment Details */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Pagamento
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Transação:</Typography>
            <Typography>{transactionId}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Método de pagamento:</Typography>
            <Typography>PIX</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Status do pagamento:</Typography>
            <Typography sx={{ color: '#22c55e', fontWeight: 500 }}>Pago</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Parcelas:</Typography>
            <Typography>1</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Customer Data */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <ShoppingBagOutlinedIcon sx={{ color: '#64748b' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Dados do cliente
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          {/* Only show fields if their respective black mode settings are false */}
          {!product?.black_mode_name_enabled && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Nome:</Typography>
              <Typography>{fullName}</Typography>
            </Box>
          )}
          {!product?.black_mode_email_enabled && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">E-mail:</Typography>
              <Typography>{formData?.email}</Typography>
            </Box>
          )}
          {!product?.black_mode_phone_enabled && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Telefone:</Typography>
              <Typography>{formData?.whatsapp}</Typography>
            </Box>
          )}
          {!product?.black_mode_cpf_enabled && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">CPF:</Typography>
              <Typography>{formData?.cpf}</Typography>
            </Box>
          )}
        </Box>
      </Paper>

      {/* Seller Info */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Responsável pela venda
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Nome:</Typography>
            <Typography>{product?.seller_product_name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">E-mail:</Typography>
            <Typography>{product?.seller_email ? product.seller_email : 'Carregando informações de suporte...'}</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Purchase Items */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Itens da sua compra
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Main Product */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box 
              component="div"
              sx={{ 
                width: 60, 
                height: 60, 
                bgcolor: '#f1f5f9',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ShoppingBagOutlinedIcon sx={{ color: '#64748b', fontSize: 30 }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 500 }}>{productTitle}</Typography>
                <Typography sx={{ color: '#22c55e', fontWeight: 500 }}>
                  {formatCurrency(productPrice)}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Additional Offers */}
          {selectedOffers?.map((offer, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box 
                component="div"
                sx={{ 
                  width: 60, 
                  height: 60, 
                  bgcolor: '#f1f5f9',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ShoppingBagOutlinedIcon sx={{ color: '#64748b', fontSize: 30 }} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 500 }}>{offer.title}</Typography>
                  <Typography sx={{ color: '#22c55e', fontWeight: 500 }}>
                    {formatCurrency(offer.price)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>

      {/* Footer */}
      <Box sx={{ mt: 'auto', textAlign: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 2 }}>
          <Typography sx={{ color: '#64748b' }}>{product?.seller_product_name}</Typography>
          <Chip 
            label="Suporte"
            size="small"
            sx={{ 
              bgcolor: '#22c55e20',
              color: '#22c55e',
              fontWeight: 500,
              borderRadius: '6px'
            }} 
          />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Em caso de dúvidas entre em contato
        </Typography>
        <Typography variant="body2" sx={{ color: '#22c55e', mb: 3 }}>
          Email: {product?.seller_email ? product.seller_email : 'Carregando informações de suporte...'}
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Mundpay está processando este pedido à serviço de {product?.seller_product_name}. 
            Ao prosseguir você esta concordando com os{' '}
            <a href="#" style={{ color: '#6366f1', textDecoration: 'none' }}>
              termos de uso
            </a>
            .
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <PublicIcon sx={{ color: '#64748b' }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmacaoPage;
