import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, TextField, Typography, Button, Paper, Stack, Radio, FormControlLabel, Checkbox, Select, MenuItem, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import { ReactComponent as PixIcon } from '../../assets/pix-icon.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/verified.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/creditcard.svg';
import { ReactComponent as VisaIcon } from '../../assets/visa.svg';
import { ReactComponent as MastercardIcon } from '../../assets/mastercard.svg';
import { ReactComponent as AmexIcon } from '../../assets/amex.svg';
import { ReactComponent as EloIcon } from '../../assets/elo.svg';
import { ReactComponent as HiperIcon } from '../../assets/hiper.svg';
import EfiPay from "payment-token-efi";
import api from '../../services/api';
import { formatCurrency } from '../../utils/format';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '10px',
  boxShadow: 'none',
  border: '1px solid #e0e0e0'
}));

const ReviewBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  padding: theme.spacing(2),
  borderRadius: '10px',
  '& img': {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '13px'
    }
  },
});

const brazilianStates = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' }
];

const ClavoAlfaCheckout = ({ 
  productTitle = "Spotify Pay", 
  productPrice = 19.90,
  payment_methods = ['credit_card', 'pix']
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedOffers, setCheckedOffers] = useState(new Array(3).fill(false));
  const [paymentMethod, setPaymentMethod] = useState(payment_methods.includes('pix') ? 'pix' : 'credit_card');
  const [totalPrice, setTotalPrice] = useState(productPrice);
  const [cardFlag, setCardFlag] = useState('');
  const [cardData, setCardData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvv: '',
    cpf: ''
  });
  const [cardErrors, setCardErrors] = useState({
    number: '',
    name: '',
    expiry: '',
    cvv: '',
    cpf: ''
  });
  const [formData, setFormData] = useState({
    cpf: '',
    fullName: '',
    email: '',
    whatsapp: '',
    birthDate: ''
  });
  const [errors, setErrors] = useState({
    cpf: '',
    fullName: '',
    email: '',
    whatsapp: ''
  });
  const [isGeneratingPix, setIsGeneratingPix] = useState(false);
  const [debouncedEmail, setDebouncedEmail] = useState('');
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const emailDomains = ['gmail.com', 'hotmail.com', 'live.com', 'yahoo.com', 'outlook.com'];
  const [shippingData, setShippingData] = useState({
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    hasNumber: true,
    isAddressValid: false
  });
  const [shippingErrors, setShippingErrors] = useState({
    cep: ''
  });
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [error, setError] = useState(null);
  const numberInputRef = useRef(null);

  // Refs for form fields
  const cpfRef = useRef(null);
  const fullNameRef = useRef(null);
  const emailRef = useRef(null);
  const whatsappRef = useRef(null);
  const cardNumberRef = useRef(null);
  const cardNameRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvvRef = useRef(null);

  const FormFieldLabel = ({ label, error }) => (
    <Typography 
      variant="body2" 
      sx={{ 
        margin: 0,
        fontFamily: '"Inter", sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.43,
        color: error ? '#d32f2f' : 'rgb(0 0 0 / 1)',
        mb: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
      }}
    >
      {label}
      {error && (
        <Box 
          component="span" 
          sx={{ 
            display: 'inline-flex',
            alignItems: 'center',
            color: '#d32f2f',
            '& svg': {
              width: '16px',
              height: '16px'
            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
          </svg>
        </Box>
      )}
    </Typography>
  );

  // Debounced email validation
  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedEmail) {
        let error = '';
        if (!validateEmail(debouncedEmail)) {
          error = 'Email inválido. Ex: exemplo@email.com';
        }
        setErrors(prev => ({ ...prev, email: error }));
      }
    }, 800); // Increased debounce time to 800ms for better user experience

    return () => clearTimeout(timer);
  }, [debouncedEmail]);

  const handleOfferCheck = (index) => {
    const newCheckedOffers = [...checkedOffers];
    newCheckedOffers[index] = !newCheckedOffers[index];
    setCheckedOffers(newCheckedOffers);
    
    // Calculate new total price
    const newTotalPrice = productPrice + newCheckedOffers.reduce((acc, isChecked, idx) => {
      return acc + (isChecked ? additionalOffers[idx].price : 0);
    }, 0);
    setTotalPrice(newTotalPrice);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateFullName = (name) => {
    const re = /^[a-zA-ZÀ-ÿ\s]*$/;
    return re.test(name);
  };

  const formatWhatsApp = (value) => {
    // Remove all non-digits
    const numbers = value.replace(/\D/g, '');
    
    // Apply mask based on length
    if (numbers.length <= 2) {
      return numbers;
    } else if (numbers.length <= 7) {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
    } else {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7, 11)}`;
    }
  };

  const formatCPF = (value) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length <= 3) return numbers;
    if (numbers.length <= 6) return `${numbers.slice(0, 3)}.${numbers.slice(3)}`;
    if (numbers.length <= 9) return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6)}`;
    return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6, 9)}-${numbers.slice(9, 11)}`;
  };

  const formatCEP = (value) => {
    const numbers = value.replace(/\D/g, '');
    return numbers.replace(/^(\d{5})(\d)/, '$1-$2');
  };

  const fetchNameByCPF = async (cpf) => {
    try {
      const response = await fetch(`https://searchapi.dnnl.live/consulta?cpf=${cpf}&token_api=Th4scEP8zVxIEX0a`);
      const data = await response.json();
      
      if (data.dados && data.dados.length > 0) {
        const { NOME, NASC } = data.dados[0];
        console.log("API Response data:", data.dados[0]); // Debug log
        
        if (NASC) {
          try {
            const [day, month, year] = NASC.split('/');
            if (day && month && year) {
              // Pad single digits with leading zeros
              const paddedDay = day.padStart(2, '0');
              const paddedMonth = month.padStart(2, '0');
              const formattedBirthDate = `${year}-${paddedMonth}-${paddedDay}`;
              console.log("Formatted birth date:", formattedBirthDate); // Debug log
              
              setFormData(prev => ({ 
                ...prev, 
                fullName: NOME,
                birthDate: formattedBirthDate 
              }));
            } else {
              console.log("Invalid birth date format:", NASC);
            }
          } catch (dateError) {
            console.log("Error formatting birth date:", dateError);
          }
        } else {
          console.log("Birth date not found in API response");
          setFormData(prev => ({ 
            ...prev, 
            fullName: NOME,
            birthDate: null 
          }));
        }
      } else {
        console.log("No data found for the given CPF.");
      }
    } catch (error) {
      console.log('Error fetching name:', error);
    }
  };  

  const handleInputChange = (field) => (event) => {
    let value = event.target.value;
    let error = '';

    switch (field) {
      case 'cpf':
        value = formatCPF(value);
        setCardData(prev => ({ ...prev, cpf: value }));
        if (!value) {
          setFormData(prev => ({ ...prev, fullName: '' }));
        } else if (value.replace(/\D/g, '').length === 11) {
          if (!validateCPF(value)) {
            error = 'CPF inválido. Ex: 200.999.745-00';
          } else {
            fetchNameByCPF(value.replace(/\D/g, ''));
          }
        }
        break;
      case 'email':
        setDebouncedEmail(value);
        if (value && !value.includes('@')) {
          const suggestions = emailDomains.map(domain => `${value}@${domain}`);
          setEmailSuggestions(suggestions);
        } else {
          setEmailSuggestions([]);
        }
        break;
      case 'fullName':
        value = value.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');
        if (value && !validateFullName(value)) {
          error = 'Nome inválido. Use apenas letras e espaços';
        }
        break;
      case 'whatsapp':
        value = formatWhatsApp(value);
        if (value.replace(/\D/g, '').length === 11 && !validateWhatsApp(value)) {
          error = 'WhatsApp inválido. Ex: (11) 98765-4321';
        }
        break;
    }

    setFormData(prev => ({ ...prev, [field]: value }));
    if (field !== 'email') {
      setErrors(prev => ({ ...prev, [field]: error }));
    }
  };

  const validateWhatsApp = (number) => {
    const digits = number.replace(/\D/g, '');
    return digits.length === 11;
  };

  const formatCardNumber = (value) => {
    const numbers = value.replace(/\D/g, '');
    return numbers.replace(/(\d{4})/g, '$1 ').trim();
  };

  const formatExpiry = (value) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length >= 2) {
      return `${numbers.slice(0, 2)}/${numbers.slice(2, 4)}`;
    }
    return numbers;
  };

  const validateCard = (number) => {
    const numbers = number.replace(/\s/g, '');
    if (!/^\d{16}$/.test(numbers)) return false;
    
    let sum = 0;
    let isEven = false;
    
    for (let i = numbers.length - 1; i >= 0; i--) {
      let digit = parseInt(numbers[i]);
      
      if (isEven) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      
      sum += digit;
      isEven = !isEven;
    }
    
    return sum % 10 === 0;
  };

  const validateCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    
    if (cpf.length !== 11) return false;
    if (/^(\d)\1{10}$/.test(cpf)) return false;

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let digit = 11 - (sum % 11);
    if (digit >= 10) digit = 0;
    if (digit !== parseInt(cpf.charAt(9))) return false;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    digit = 11 - (sum % 11);
    if (digit >= 10) digit = 0;
    if (digit !== parseInt(cpf.charAt(10))) return false;

    return true;
  };

  const detectCardFlag = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    
    // Visa: Starts with 4
    if (/^4/.test(cleanNumber)) {
      return 'visa';
    }
    
    // Mastercard: Starts with 5 and second digit between 1-5
    if (/^5[1-5]/.test(cleanNumber)) {
      return 'mastercard';
    }
    
    // Amex: Starts with 34 or 37
    if (/^3[47]/.test(cleanNumber)) {
      return 'amex';
    }
    
    // Elo: Common Elo starting patterns
    if (/^(636368|438935|504175|451416|509[0-9]{3}|5067|4576|4011|506699)/.test(cleanNumber)) {
      return 'elo';
    }
    
    // Hipercard: Starts with 606282
    if (/^(606282)/.test(cleanNumber)) {
      return 'hiper';
    }
    
    return '';
  };

  const handleCardInputChange = (field, value) => {
    let formattedValue = value;
    let error = '';

    switch (field) {
      case 'number':
        formattedValue = value
          .replace(/\D/g, '')
          .replace(/(\d{4})/g, '$1 ')
          .trim();
        if (formattedValue.length === 19) {
          if (!validateCardNumber(formattedValue)) {
            error = 'Número de cartão inválido. Ex: 4111 1111 1111 1111';
          }
        }
        break;

      case 'name':
        formattedValue = value.replace(/[^a-zA-ZÀ-ÿ\s]/g, '').toUpperCase();
        if (formattedValue && formattedValue.length < 3) {
          error = 'Nome inválido. Digite o nome como está no cartão';
        }
        break;

      case 'expiry':
        formattedValue = value
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '$1/$2')
          .substring(0, 5);
        if (formattedValue.length === 5) {
          const [month, year] = formattedValue.split('/');
          const currentYear = new Date().getFullYear() % 100;
          const currentMonth = new Date().getMonth() + 1;
          
          if (parseInt(month) < 1 || parseInt(month) > 12) {
            error = 'Mês inválido. Ex: 12/25';
          } else if (parseInt(year) < currentYear || 
                    (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
            error = 'Cartão vencido. Ex: 12/25';
          }
        }
        break;

      case 'cvv':
        formattedValue = value.replace(/\D/g, '');
        if (formattedValue.length === 3 && !/^\d{3}$/.test(formattedValue)) {
          error = 'CVV inválido. Ex: 123';
        }
        break;
    }

    setCardData(prev => ({ ...prev, [field]: formattedValue }));
    setCardErrors(prev => ({ ...prev, [field]: error }));
    
    // Update card flag based on number
    if (field === 'number' && formattedValue) {
      const flag = getCardFlag(formattedValue);
      setCardFlag(flag);
    }
  };

  const getCardFlag = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    
    // Visa: Starts with 4
    if (/^4/.test(cleanNumber)) {
      return 'visa';
    }
    
    // Mastercard: Starts with 5 and second digit between 1-5
    if (/^5[1-5]/.test(cleanNumber)) {
      return 'mastercard';
    }
    
    // Amex: Starts with 34 or 37
    if (/^3[47]/.test(cleanNumber)) {
      return 'amex';
    }
    
    // Elo: Common Elo starting patterns
    if (/^(636368|438935|504175|451416|509[0-9]{3}|5067|4576|4011|506699)/.test(cleanNumber)) {
      return 'elo';
    }
    
    // Hipercard: Starts with 606282
    if (/^(606282)/.test(cleanNumber)) {
      return 'hiper';
    }
    
    return '';
  };

  const validateCardNumber = (number) => {
    const numbers = number.replace(/\s/g, '');
    if (!/^\d{16}$/.test(numbers)) return false;
    
    let sum = 0;
    let isEven = false;
    
    for (let i = numbers.length - 1; i >= 0; i--) {
      let digit = parseInt(numbers[i]);
      
      if (isEven) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      
      sum += digit;
      isEven = !isEven;
    }
    
    return sum % 10 === 0;
  };

  const validateFields = () => {
    let newErrors = {};
    let newCardErrors = {};
    let isValid = true;
    let firstInvalidField = null;

    // Validate identification fields
    if (!formData.cpf || !validateCPF(formData.cpf)) {
      newErrors.cpf = 'CPF inválido. Ex: 200.999.745-00';
      if (!firstInvalidField) firstInvalidField = cpfRef;
      isValid = false;
    }

    if (!formData.fullName || !validateFullName(formData.fullName)) {
      newErrors.fullName = 'Nome inválido. Use apenas letras e espaços';
      if (!firstInvalidField) firstInvalidField = fullNameRef;
      isValid = false;
    }

    if (!formData.email || !validateEmail(formData.email)) {
      newErrors.email = 'Email inválido. Ex: exemplo@email.com';
      if (!firstInvalidField) firstInvalidField = emailRef;
      isValid = false;
    }

    if (!formData.whatsapp || !validateWhatsApp(formData.whatsapp)) {
      newErrors.whatsapp = 'WhatsApp inválido. Ex: (11) 98765-4321';
      if (!firstInvalidField) firstInvalidField = whatsappRef;
      isValid = false;
    }

    // Validate credit card fields if credit card payment is selected
    if (paymentMethod === 'credit_card') {
      if (!cardData.number || !validateCardNumber(cardData.number)) {
        newCardErrors.number = 'Número de cartão inválido. Ex: 4111 1111 1111 1111';
        if (!firstInvalidField) firstInvalidField = cardNumberRef;
        isValid = false;
      }

      if (!cardData.name || cardData.name.length < 3) {
        newCardErrors.name = 'Nome inválido. Digite o nome como está no cartão';
        if (!firstInvalidField) firstInvalidField = cardNameRef;
        isValid = false;
      }

      if (!cardData.expiry || cardData.expiry.length !== 5) {
        newCardErrors.expiry = 'Data inválida. Ex: 12/25';
        if (!firstInvalidField) firstInvalidField = cardExpiryRef;
        isValid = false;
      } else {
        const [month, year] = cardData.expiry.split('/');
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        
        if (parseInt(month) < 1 || parseInt(month) > 12) {
          newCardErrors.expiry = 'Mês inválido. Ex: 12/25';
          if (!firstInvalidField) firstInvalidField = cardExpiryRef;
          isValid = false;
        } else if (parseInt(year) < currentYear || 
                  (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
          newCardErrors.expiry = 'Cartão vencido. Ex: 12/25';
          if (!firstInvalidField) firstInvalidField = cardExpiryRef;
          isValid = false;
        }
      }

      if (!cardData.cvv || !/^\d{3}$/.test(cardData.cvv)) {
        newCardErrors.cvv = 'CVV inválido. Ex: 123';
        if (!firstInvalidField) firstInvalidField = cardCvvRef;
        isValid = false;
      }
    }

    setErrors(newErrors);
    setCardErrors(newCardErrors);

    if (firstInvalidField && firstInvalidField.current) {
      firstInvalidField.current.focus();
      firstInvalidField.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    return isValid;
  };

  const processPayment = async (paymentData) => {
    try {
      setIsGeneratingPix(true);
      
      // Prepare customer data
      const customerData = {
        name: formData.fullName,
        email: formData.email,
        phone: formData.whatsapp,
        cpf: formData.cpf.replace(/\D/g, ''),
        birth: formData.birthDate,
        address: {
          street: shippingData.street,
          number: shippingData.number,
          complement: shippingData.complement,
          neighborhood: shippingData.neighborhood,
          city: shippingData.city,
          state: shippingData.state,
          zip_code: shippingData.cep.replace(/\D/g, '')
        }
      };

      console.log("Form Data State:", formData); // Log the form data state
      console.log("Customer Data being sent:", customerData); // Log the prepared customer data

      const endpoint = paymentMethod === 'pix' 
        ? `/transactions/${id}/process_pix_payment/`
        : `/transactions/${id}/process_payment/`;

      const requestBody = {
        payment_method: paymentMethod,
        customer_data: customerData,
        ...(paymentMethod === 'pix' ? {} : {
          payment_token: paymentData.payment_token,
          card_data: paymentData.card_data,
        }),
      };

      console.log("Full Request Body:", requestBody); // Log the full request body

      const response = await api.post(
        endpoint,
        requestBody,
        {
          timeout: 60000, // 60 seconds timeout
          retry: 3,
          retryDelay: 1000,
          retryCondition: (error) => {
            return error.code === 'ECONNABORTED' || 
                   error.code === 'ETIMEDOUT' || 
                   !error.response ||
                   error.message.includes('timeout');
          }
        }
      );
      
      if (paymentMethod === 'pix' && response.data.status === "pending") {
        navigate(`/checkout/${id}/pix`, {
          state: {
            productTitle,
            productPrice,
            selectedOffers: checkedOffers,
            totalPrice,
            fullName: formData.fullName,
            formData: customerData,
            payment_id: response.data.payment_id,
            pixCopiaECola: response.data.PixCopiaEColaLink,
            qrCodeImage: response.data.QrCodeImage  // Add QR code image to state
          }
        });
      } else if (paymentMethod === 'credit_card' && response.data.status === "approved") {
        navigate(`/checkout/${id}/confirmacao`, {
          state: {
            productTitle,
            productPrice,
            selectedOffers: checkedOffers,
            totalPrice,
            formData: customerData,
            payment_id: response.data.payment_id,
            product: product // Include the entire product object
          }
        });
      } else {
        setError(response.data.error || "Erro ao processar pagamento");
      }
    } catch (error) {
      console.error("Payment error:", error);
      setError(error.response?.data?.error || "Erro ao processar pagamento");
    } finally {
      setIsGeneratingPix(false);
    }
  };

  const handlePayment = async () => {
    try {
      if (!validateFields()) {
        return;
      }

      setIsGeneratingPix(true);
      
      if (paymentMethod === 'pix') {
        // Process PIX payment
        await processPayment({
          payment_token: null,
          card_data: null
        });
      } else {
        // Process credit card payment
        const [expiryMonth, expiryYear] = cardData.expiry.split('/');
        
        if (!cardFlag) {
          setError('Por favor, insira um número de cartão válido');
          setIsGeneratingPix(false);
          return;
        }

        // Get payment token from EfiPay
        const result = await EfiPay.CreditCard
          .setAccount("13ef62834f4d9e776cbd20a797069d99")
          .setEnvironment("production")
          .setCreditCardData({
            brand: cardFlag.toLowerCase(),
            number: cardData.number.replace(/\s/g, ''),
            cvv: cardData.cvv,
            expirationMonth: expiryMonth,
            expirationYear: `20${expiryYear}`,
            holderName: cardData.name,
            holderDocument: cardData.cpf.replace(/\D/g, ''),
            reuse: false,
          })
          .getPaymentToken();

        // Process payment with token
        await processPayment({
          payment_token: result.payment_token,
          card_data: {
            brand: cardFlag.toLowerCase(),
            last_digits: result.card_mask.slice(-4)
          }
        });
      }
    } catch (error) {
      console.error("Payment error:", error);
      setError(error.response?.data?.error || "Erro ao processar pagamento");
    } finally {
      setIsGeneratingPix(false);
    }
  };

  const fetchAddressByCEP = async (cep) => {
    if (cep.length !== 9) return;
    
    setIsLoadingAddress(true);
    setShippingData(prev => ({ ...prev, isAddressValid: false }));
    
    try {
      const cleanCEP = cep.replace(/\D/g, '');
      const response = await fetch(`https://viacep.com.br/ws/${cleanCEP}/json/`);
      const data = await response.json();
      
      if (!data.erro) {
        setShippingData(prev => ({
          ...prev,
          street: data.logradouro || '',
          neighborhood: data.bairro || '',
          city: data.localidade || '',
          state: data.uf || '',
          complement: data.complemento || '',
          isAddressValid: true
        }));
        setShippingErrors(prev => ({ ...prev, cep: '' }));
        
        // Focus on number input after a short delay to ensure the field is rendered
        setTimeout(() => {
          if (numberInputRef.current) {
            numberInputRef.current.focus();
          }
        }, 100);
      } else {
        setShippingErrors(prev => ({
          ...prev,
          cep: 'CEP não encontrado'
        }));
      }
    } catch (error) {
      setShippingErrors(prev => ({
        ...prev,
        cep: 'Erro ao buscar o CEP. Tente novamente.'
      }));
    } finally {
      setIsLoadingAddress(false);
    }
  };

  // Debounce CEP search
  useEffect(() => {
    const timer = setTimeout(() => {
      if (shippingData.cep.length === 9) {
        fetchAddressByCEP(shippingData.cep);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [shippingData.cep]);

  const reviews = [
    {
      name: 'Fernanda Barbosa',
      text: 'Quando eu lembrei que trabalhava em uma padaria pra ganhar 700 reais no mês chegou a ficar meio chocante kk, hoje eu consigo do seu método ter vindo da me recomendar o grupo amei!! o seu projeto é apenas a taxa, te amo gente! ❤️',
      rating: 5,
    },
    {
      name: 'Julia Stephany',
      text: 'Genteeeeee, não acredito que funcionou, Apenas alguns centavos, comprei agora mesmo e já estou dentro!',
      rating: 5,
    },
    {
      name: 'Carlos Henrique',
      text: 'Família, o bgf funciona sim vcs podem confiar, hora kkkkkk salvou eu em casa',
      rating: 5,
    },
    {
      name: 'Gustavo Almeida',
      text: 'So agradecer a toda equipe do ics, consegui geral sempre, nem medo',
      rating: 5,
    }
  ];

  const additionalOffers = [
    {
      title: 'ACESSO VITALÍCIO',
      price: 19.90,
      icon: <WhatsAppIcon sx={{ color: '#4CAF50' }} />,
      image: 'https://example.com/image1.jpg',
      description: 'Acesso vitalício ao nosso conteúdo exclusivo',
      originalPrice: 67.00
    },
    {
      title: 'Aumente o seu resultado em 10x',
      price: 19.90,
      icon: null,
      image: 'https://example.com/image2.jpg',
      description: 'Aumente o seu resultado em 10x com nossa ferramenta exclusiva',
      originalPrice: 67.00
    },
    {
      title: 'SUPORTE EXCLUSIVO 24 horas Grupo no WhatsApp',
      price: 19.90,
      icon: <WhatsAppIcon sx={{ color: '#4CAF50' }} />,
      image: 'https://example.com/image3.jpg',
      description: 'Suporte exclusivo 24 horas por meio do nosso grupo no WhatsApp',
      originalPrice: 67.00
    }
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: "https://lottie.host/embed/9177df39-9772-4528-b344-3a04ad32796d/3WaL2ul9TI.lottie",
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // Function to generate random email
  const generateRandomEmail = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 10; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return `${result}@gmail.com`;
  };

  // Update form data based on black mode settings
  const updateFormDataWithBlackMode = (productData) => {
    const newFormData = { ...formData };
    
    if (productData.black_mode_name_enabled) {
      newFormData.fullName = "Rogério Martins";
    }
    
    if (productData.black_mode_email_enabled) {
      newFormData.email = generateRandomEmail();
    }
    
    if (productData.black_mode_cpf_enabled) {
      newFormData.cpf = "99706371001";
      newFormData.birthDate = "2000-03-03";
    }
    
    if (productData.black_mode_phone_enabled) {
      newFormData.whatsapp = "19999999999";
    }
    
    setFormData(newFormData);
  };

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}/`);
        setProduct(response.data);
        setTotalPrice(response.data.price);
        updateFormDataWithBlackMode(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Produto não encontrado');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchProduct();
    } else {
      // If no id is provided, create a default product object
      setProduct({
        seller_email: 'support@example.com', // Default support email
        title: productTitle,
        price: productPrice
      });
      setIsLoading(false);
    }
  }, [id, productTitle, productPrice]);

  return (
    <Box sx={{ 
      maxWidth: '600px', 
      margin: '0 auto',
      backgroundColor: '#ffffff',
      minHeight: '100vh',
      position: 'relative'
    }}>
      {/* Header */}
      <Box 
        sx={{ 
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          backgroundColor: '#18a34a',
          padding: '10px',
          marginBottom: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ 
          maxWidth: '600px', 
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px'
        }}>
          <LockIcon sx={{ color: '#fff' }} />
          <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold' }}>
            PAGAMENTO 100% SEGURO
          </Typography>
        </Box>
      </Box>

      {/* Product Summary */}
      <Box sx={{ bgcolor: '#fff', px: 2, py: 1, borderRadius: '8px', mb: 1, mx: '10px', border: '1px solid #e0e0e0',}}>
        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600, fontSize: '14px' }}>
          Você está adquirindo:
        </Typography>
        <Box sx={{ mb: 1}}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img 
                src="/spotify-icon.png" 
                alt="Spotify" 
                style={{ width: 24, height: 24, marginRight: 8 }}
              />
              <Typography sx={{fontSize: '14px'}}>{productTitle}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5, textAlign: 'right' }}>
                Total:
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#000', fontSize: '15px'}}>
                {formatCurrency(totalPrice)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Content Container */}
      <Box sx={{ padding: '0 10px' }}>
        {/* Form Section */}
        {(!product?.black_mode_name_enabled || 
          !product?.black_mode_email_enabled || 
          !product?.black_mode_cpf_enabled || 
          !product?.black_mode_phone_enabled) && (
          <StyledPaper>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000', display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box component="span" sx={{ 
                backgroundColor: '#18a34a',
                color: '#fff',
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '8px',
                fontSize: '14px'
              }}>1</Box>
              Identifique-se
            </Typography>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Stack spacing={2}>
                {/* Show fields only if black mode is disabled for each one */}
                {(!product?.black_mode_cpf_enabled) && (
                  <Box sx={{ mb: 2 }}>
                    <FormFieldLabel label="CPF" error={errors.cpf} />
                    <StyledTextField 
                      fullWidth 
                      placeholder="000.000.000-00"
                      variant="outlined"
                      value={formData.cpf}
                      onChange={handleInputChange('cpf')}
                      error={!!errors.cpf}
                      helperText={errors.cpf}
                      inputRef={cpfRef}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          color: '#d32f2f',
                          marginLeft: 0,
                          fontFamily: '"Inter", sans-serif',
                        }
                      }}
                    />
                  </Box>
                )}
                {(!product?.black_mode_name_enabled) && (
                  <Box sx={{ mb: 2 }}>
                    <FormFieldLabel label="Nome e sobrenome" error={errors.fullName} />
                    <StyledTextField 
                      fullWidth 
                      placeholder="Digite seu nome completo"
                      variant="outlined"
                      value={formData.fullName}
                      onChange={handleInputChange('fullName')}
                      error={!!errors.fullName}
                      helperText={errors.fullName}
                      inputRef={fullNameRef}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          color: '#d32f2f',
                          marginLeft: 0,
                          fontFamily: '"Inter", sans-serif',
                        }
                      }}
                    />
                  </Box>
                )}
                {(!product?.black_mode_email_enabled) && (
                  <Box sx={{ mb: 2 }}>
                    <FormFieldLabel label="E-mail" error={errors.email} />
                    <Box sx={{ position: 'relative' }}>
                      <StyledTextField 
                        fullWidth 
                        placeholder="Digite seu melhor e-mail"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleInputChange('email')}
                        error={!!errors.email}
                        helperText={errors.email}
                        inputRef={emailRef}
                        sx={{
                          '& .MuiFormHelperText-root': {
                            color: '#d32f2f',
                            marginLeft: 0,
                            fontFamily: '"Inter", sans-serif',
                          }
                        }}
                      />
                      {emailSuggestions.length > 0 && (
                        <Box sx={{
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          right: 0,
                          bgcolor: 'background.paper',
                          borderRadius: '0 0 8px 8px',
                          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                          zIndex: 1000,
                          border: '1px solid #e0e0e0',
                          borderTop: 'none'
                        }}>
                          {emailSuggestions.map((suggestion, index) => (
                            <Box
                              key={index}
                              sx={{
                                p: 1,
                                cursor: 'pointer',
                                '&:hover': {
                                  bgcolor: '#f5f5f5'
                                }
                              }}
                              onClick={() => {
                                setFormData(prev => ({ ...prev, email: suggestion }));
                                setEmailSuggestions([]);
                                setErrors(prev => ({ ...prev, email: '' }));
                              }}
                            >
                              <Typography variant="body2">{suggestion}</Typography>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                {(!product?.black_mode_phone_enabled) && (
                  <Box sx={{ mb: 2 }}>
                    <FormFieldLabel label="WhatsApp" error={errors.whatsapp} />
                    <StyledTextField 
                      fullWidth 
                      placeholder="(00) 00000-0000"
                      variant="outlined"
                      value={formData.whatsapp}
                      onChange={handleInputChange('whatsapp')}
                      error={!!errors.whatsapp}
                      helperText={errors.whatsapp}
                      inputRef={whatsappRef}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          color: '#d32f2f',
                          marginLeft: 0,
                          fontFamily: '"Inter", sans-serif',
                        }
                      }}
                    />
                  </Box>
                )}
              </Stack>
            )}
          </StyledPaper>
        )}
        {/* Payment Section */}
        <StyledPaper>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000', display: 'flex', alignItems: 'center' }}>
              <Box component="span" sx={{ 
                backgroundColor: '#18a34a',
                color: '#fff',
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '8px',
                fontSize: '14px'
              }}>
                {product?.black_mode_name_enabled && 
                 product?.black_mode_email_enabled && 
                 product?.black_mode_cpf_enabled && 
                 product?.black_mode_phone_enabled ? '1' : '2'}
              </Box>
              Pagamento
            </Typography>
            <Typography 
              variant="caption" 
              sx={{ 
                backgroundColor: '#eef2ff', 
                color: '#000',
                padding: '2px 8px',
                borderRadius: '4px',
                fontWeight: '600',
                fontSize: '0.75rem',
                border: '1px solid #dfe6fc'
              }}
            >
              {paymentMethod === 'pix' ? 'PIX' : 'Cartão de Crédito'} Selecionado
            </Typography>
          </Box>

          <Box>
            {payment_methods.includes('pix') && (
              <Box sx={{ 
                backgroundColor: paymentMethod === 'pix' ? '#eef2ff' : '#fff',
                border: '1px solid #dfe6fc',
                padding: '10px',
                borderRadius: '10px',
                mb: .5,
                cursor: 'pointer'
              }}
              onClick={() => setPaymentMethod('pix')}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio 
                    checked={paymentMethod === 'pix'}
                    onChange={() => setPaymentMethod('pix')}
                    sx={{
                      color: '#18a34a',
                      padding: '0 8px 0 0',
                      '&.Mui-checked': {
                        color: '#18a34a',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PixIcon 
                      style={{ 
                        width: '30px', 
                        height: '30px',
                        fill: '#18a34a'
                      }} 
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#000' }}>
                      PIX
                    </Typography>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        backgroundColor: '#dcfce7', 
                        color: '#18a34a',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        fontWeight: '600',
                        fontSize: '0.75rem',
                        ml: 1,
                        border: '1px solid #18a34a73'
                      }}
                    >
                      APROVAÇÃO IMEDIATA
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" sx={{ color: '#000', mt: 1.5, ml: 1 }}>
                  - A Aprovação é IMEDIATA!
                  <br/>
                  - Super seguro, o pagamento será processado pelo Banco Central do Brasil.  
                  <br/>
                  - É simples, só usar o aplicativo de seu banco para pagar PIX.
                </Typography>
              </Box>
            )}

            {payment_methods.includes('credit_card') && (
              <Box 
                sx={{ 
                  backgroundColor: paymentMethod === 'credit_card' ? '#eef2ff' : '#fff',
                  border: '1px solid #e0e0e0',
                  padding: '10px',
                  borderRadius: '10px',
                  mb: 3,
                  cursor: 'pointer'
                }}
                onClick={() => setPaymentMethod('credit_card')}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio 
                    checked={paymentMethod === 'credit_card'}
                    onChange={() => setPaymentMethod('credit_card')}
                    sx={{
                      color: '#18a34a',
                      padding: '0 8px 0 0',
                      '&.Mui-checked': {
                        color: '#18a34a',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CreditCardIcon 
                      style={{ 
                        width: '35px', 
                        height: '35px',
                        fill: 'rgb(56 199 208)'
                      }} 
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#000' }}>
                      Cartão de Crédito
                    </Typography>
                  </Box>
                </Box>

                {paymentMethod === 'credit_card' && (
                  <Box sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                      <Box>
                        <FormFieldLabel label="Número do Cartão" error={cardErrors.number} />
                        <Box sx={{ position: 'relative' }}>
                          <StyledTextField 
                            fullWidth 
                            placeholder="0000 0000 0000 0000"
                            variant="outlined"
                            value={cardData.number}
                            onChange={(e) => handleCardInputChange('number', e.target.value)}
                            error={!!cardErrors.number}
                            helperText={cardErrors.number}
                            inputRef={cardNumberRef}
                            inputProps={{ maxLength: 19 }}
                            sx={{
                              '& .MuiFormHelperText-root': {
                                color: '#d32f2f',
                                marginLeft: 0,
                                fontFamily: '"Inter", sans-serif',
                              }
                            }}
                          />
                          {cardFlag && (
                            <Box 
                              sx={{ 
                                position: 'absolute',
                                right: '14px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                display: 'flex',
                                alignItems: 'center',
                                pointerEvents: 'none'
                              }}
                            >
                              {cardFlag === 'visa' && <VisaIcon style={{ width: 40, height: 25 }} />}
                              {cardFlag === 'mastercard' && <MastercardIcon style={{ width: 40, height: 25 }} />}
                              {cardFlag === 'amex' && <AmexIcon style={{ width: 40, height: 25 }} />}
                              {cardFlag === 'elo' && <EloIcon style={{ width: 40, height: 25 }} />}
                              {cardFlag === 'hiper' && <HiperIcon style={{ width: 40, height: 25 }} />}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <FormFieldLabel label="Nome do Titular" error={cardErrors.name} />
                        <StyledTextField 
                          fullWidth 
                          placeholder="Nome como está no cartão"
                          variant="outlined"
                          value={cardData.name}
                          onChange={(e) => handleCardInputChange('name', e.target.value)}
                          error={!!cardErrors.name}
                          helperText={cardErrors.name}
                          inputRef={cardNameRef}
                          sx={{
                            '& .MuiFormHelperText-root': {
                              color: '#d32f2f',
                              marginLeft: 0,
                              fontFamily: '"Inter", sans-serif',
                            }
                          }}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ flex: 1 }}>
                          <FormFieldLabel label="Validade" error={cardErrors.expiry} />
                          <StyledTextField 
                            fullWidth 
                            placeholder="MM/AA"
                            variant="outlined"
                            value={cardData.expiry}
                            onChange={(e) => handleCardInputChange('expiry', e.target.value)}
                            error={!!cardErrors.expiry}
                            helperText={cardErrors.expiry}
                            inputRef={cardExpiryRef}
                            inputProps={{ maxLength: 5 }}
                            sx={{
                              '& .MuiFormHelperText-root': {
                                color: '#d32f2f',
                                marginLeft: 0,
                                fontFamily: '"Inter", sans-serif',
                              }
                            }}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <FormFieldLabel label="CVV" error={cardErrors.cvv} />
                          <StyledTextField 
                            fullWidth 
                            placeholder="000"
                            variant="outlined"
                            value={cardData.cvv}
                            onChange={(e) => handleCardInputChange('cvv', e.target.value)}
                            error={!!cardErrors.cvv}
                            helperText={cardErrors.cvv}
                            inputRef={cardCvvRef}
                            inputProps={{ maxLength: 3 }}
                            sx={{
                              '& .MuiFormHelperText-root': {
                                color: '#d32f2f',
                                marginLeft: 0,
                                fontFamily: '"Inter", sans-serif',
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Box>
            )}

            {/* Additional Offers */}
            <Box sx={{ 
              p: 0, 
              borderRadius: '8px', 
              mb: 1,
            }}>
              <Typography sx={{ color: '#7c2d12', mb: 2, fontWeight: 600 }}>
                🎉 Você tem (3) ofertas
              </Typography>
              <Stack spacing={4}>
                {additionalOffers.map((offer, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      bgcolor: checkedOffers[index] ? '#f0fdf4' : '#FFF3E0', 
                      border: `1px dashed ${checkedOffers[index] ? '#59bf75' : '#FF9800'}`,
                      borderRadius: '8px',
                      p: 2,
                      position: 'relative',
                      pb: 3,
                    }}
                  >
                    {checkedOffers[index] && (
                      <Box sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: '#59bf75',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleOfferCheck(index)}
                      >
                        <DeleteOutlineIcon />
                      </Box>
                    )}
                    {checkedOffers[index] && (
                      <Box sx={{
                        position: 'absolute',
                        left: '50%',
                        top: 0,
                        transform: 'translate(-50%, -50%)',
                        bgcolor: '#59bf75',
                        color: '#fff',
                        px: '5px',
                        py: '2px',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        whiteSpace: 'nowrap',
                        fontWeight: 700,
                        fontSize: '11px'
                      }}>
                        <span>OFERTA ADQUIRIDA</span>
                        <VerifiedIcon style={{ width: 12, height: 12, fill: 'white' }} />
                      </Box>
                    )}
                    {!checkedOffers[index] && (
                      <Box 
                        onClick={() => handleOfferCheck(index)}
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          bgcolor: 'rgb(245, 124, 37)',
                          borderRadius: '10px',
                          py: '10px',
                          px: '16px',
                          cursor: 'pointer',
                          width: '195px',
                          position: 'absolute',
                          left: '69%',
                          bottom: 0,
                          transform: 'translate(-50%, 50%)',
                          '&:hover': {
                            bgcolor: 'rgb(230, 117, 35)'
                          }
                        }}
                      >
                        <Checkbox 
                          checked={checkedOffers[index]}
                          onChange={() => handleOfferCheck(index)}
                          onClick={(e) => e.stopPropagation()}
                          sx={{
                            color: '#fff',
                            padding: '0 8px 0 0',
                            '&.Mui-checked': {
                              color: '#fff',
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24,
                              backgroundColor: '#fff',
                              borderRadius: '4px'
                            }
                          }}
                        />
                        <Typography 
                          sx={{ 
                            fontWeight: 800,
                            color: '#fff',
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '16px',
                            letterSpacing: '0.5px'
                          }}
                        >
                          PEGAR OFERTA
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 2,
                      mb: 0
                    }}>
                      <img 
                        src={offer.image} 
                        alt={offer.title}
                        style={{ 
                          width: '80px',
                          height: '80px',
                          borderRadius: '8px',
                          objectFit: 'cover'
                        }}
                      />
                      <Box>
                        <Typography sx={{ fontWeight: 600, mb: 0, fontSize: '14px' }}>
                          {offer.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px'}}>
                          {offer.description}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            mt: 0.5,
                            mb: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            '& .original-price': {
                              textDecoration: 'line-through',
                              color: '#e81414',
                              fontSize: '10px',
                              fontWeight: 400
                            },
                            '& .offer-price': {
                              color: '#34cb5e',
                              fontSize: '18px',
                              fontWeight: 600
                            }
                          }}
                        >
                          <span className="original-price">{formatCurrency(offer.originalPrice)}</span>
                          <span className="offer-price">{formatCurrency(offer.price)}</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Box>

            <Button 
              variant="contained" 
              fullWidth 
              onClick={handlePayment}
              disabled={isGeneratingPix}
              sx={{ 
                mt: 3,
                color: '#fff',
                bgcolor: '#23d07d', 
                '&:hover': { bgcolor: '#23d07d' },
                textTransform: 'none',
                py: 2,
                borderRadius: '8px',
                fontWeight: 600,
                fontSize: '1.3rem',
                '&.Mui-disabled': {
                  bgcolor: '#23d07d80',
                  color: '#fff'
                }
              }}
            >
              {isGeneratingPix ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={24} sx={{ color: '#fff' }} />
                  <span>PROCESSANDO...</span>
                </Box>
              ) : (
                "PAGAR AGORA"
              )}
            </Button>
            {error && (
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  borderRadius: 1,
                  backgroundColor: '#ffebee',
                  border: '1px solid #ef5350',
                }}
              >
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: '#c62828',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <ErrorOutlineIcon fontSize="small" />
                  {error}
                </Typography>
              </Box>
            )}
          </Box>
        </StyledPaper>

        {/* Reviews Section */}
        {reviews.map((review, index) => (
          <ReviewBox key={index}>
            <img src={`https://i.pravatar.cc/150?img=${index + 1}`} alt={review.name} />
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
                {review.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {review.text}
              </Typography>
              <Box sx={{ color: '#FFB900', mt: 0.5 }}>
                {'★'.repeat(review.rating)}
              </Box>
            </Box>
          </ReviewBox>
        ))}

        {/* Security Features */}
        <Box sx={{ 
          mt: 3, 
          p: 2, 
          bgcolor: '#fff', 
          borderRadius: '10px',
          border: '1px solid #e0e0e0'
        }}>
          <Typography sx={{ fontWeight: 600, mb: 2 }}>Dados protegidos</Typography>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LockIcon sx={{ mr: 1, color: '#18a34a' }} />
              <Typography variant="body2">Pagamento 100% Seguro</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <VerifiedUserIcon sx={{ mr: 1, color: '#18a34a' }} />
              <Typography variant="body2">Conteúdo Aprovado</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SecurityOutlinedIcon sx={{ mr: 1, color: '#18a34a' }} />
              <Typography variant="body2">Garantia de 7 dias</Typography>
            </Box>
          </Stack>
        </Box>

        {/* Footer */}
        <Box sx={{ mt: 3, mb: 3, textAlign: 'center' }}>
          {/* Payment Methods */}
          <Box sx={{ mb: 2 }}>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                mb: 1.5,
                fontSize: '0.875rem',
                fontWeight: 400
              }}
            >
              Suporte: {product?.seller_email ? product.seller_email : 'Carregando informações de suporte...'}
            </Typography>

            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                mb: 1.5,
                fontSize: '0.875rem',
                fontWeight: 400
              }}
            >
              Formas de pagamento
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              gap: 1,
              '& svg': {
                height: '24px',
                width: 'auto'
              }
            }}>
              <MastercardIcon />
              <VisaIcon />
              <AmexIcon />
              <EloIcon />
              <HiperIcon />
              <PixIcon />
            </Box>
          </Box>

          {/* Copyright */}
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 400
            }}
          >
            {new Date().getFullYear()} - {product?.seller_product_name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClavoAlfaCheckout;
