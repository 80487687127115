import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Grid,
  InputAdornment,
  Divider,
  Radio,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  FormControl,
  Modal,
} from '@mui/material';
import {
  MoreVertical,
  Edit,
  Trash,
  Plus,
  Package,
  Grid as GridIcon,
  Tag,
  Search,
  Cloud,
  Link,
  CreditCard
} from 'react-feather';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';
import Toggle from './Toggle';
import { MercadoLivreCheckout, ClavoAlfaCheckout } from './checkoutTemplates';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// Updated font styles
const interFont = {
  fontFamily: '"Inter", sans-serif',
};

// Updated color palette based on theme
const getThemeColors = (theme) => ({
  primary: '#29ae60',
  primaryHover: '#4abe7b',
  background: theme.palette.background.default,
  border: theme.palette.divider,
  text: {
    primary: theme.palette.text.primary,
    secondary: theme.palette.text.secondary,
    white: theme.palette.background.paper
  }
});

// Move TabPanel outside of ProductForm
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      aria-labelledby={`product-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2.5 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const ProductForm = ({ handleClose, handleSubmit, initialData = null }) => {
  const theme = useTheme();
  const colors = getThemeColors(theme);
  const defaultFormData = {
    title: '',
    description: '',
    price: '',
    picture_url: '',
    template: 'clavoalfa',
    payment_methods: ['credit_card', 'pix'],
    black_mode_name_enabled: false,
    black_mode_email_enabled: false,
    black_mode_cpf_enabled: false,
    black_mode_phone_enabled: false,
    support_email: '',
    seller_name: ''
  };

  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    description: initialData?.description || '',
    price: initialData?.price || '',
    promotional_price: initialData?.promotional_price || '',
    picture_url: initialData?.picture_url || '',
    template: initialData?.template || 'clavoalfa',
    payment_methods: initialData?.payment_methods || ['credit_card', 'pix'],
    fetch_cpf_data: initialData?.fetch_cpf_data || false,
    black_mode_name_enabled: initialData?.black_mode_name_enabled || false,
    black_mode_email_enabled: initialData?.black_mode_email_enabled || false,
    black_mode_cpf_enabled: initialData?.black_mode_cpf_enabled || false,
    black_mode_phone_enabled: initialData?.black_mode_phone_enabled || false,
    support_email: initialData?.seller_email || '',
    seller_name: initialData?.seller_product_name || ''
  });
  const [activeTab, setActiveTab] = useState(0);
  const [showCpfField, setShowCpfField] = useState(true);
  const [showNameField, setShowNameField] = useState(true);
  const [showEmailField, setShowEmailField] = useState(true);
  const [showPhoneField, setShowPhoneField] = useState(true);
  const [selectedUpsells, setSelectedUpsells] = useState([]);
  const [searchUpsell, setSearchUpsell] = useState('');
  const [paymentMethodError, setPaymentMethodError] = useState('');

  const [openUpsellModal, setOpenUpsellModal] = useState(false);
  const [dragActiveUpsell, setDragActiveUpsell] = useState(false);
  const [dragActiveUpsellFile, setDragActiveUpsellFile] = useState(false);
  const [upsellForm, setUpsellForm] = useState({
    name: '',
    description: '',
    image_url: '',
    file_url: '',
    price: '',
    discount_price: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    price: '',
    image_url: '',
    file_url: '',
    discount_price: ''
  });
  const [upsells, setUpsells] = useState([]);
  const [editingUpsellId, setEditingUpsellId] = useState(null);

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    price: '',
    picture_url: '',
    productImage: '',
    productFile: ''
  });

  const validateField = (name, value) => {
    switch (name) {
      case 'title':
        return !value.trim() ? 'Título é obrigatório' : '';
      case 'description':
        return !value.trim() ? 'Descrição é obrigatória' : '';
      case 'price':
        if (!value) return 'Preço é obrigatório';
        if (isNaN(value) || parseFloat(value) <= 0) return 'Preço deve ser maior que zero';
        return '';
      case 'picture_url':
        if (value && value.trim()) {  // Only validate if URL is provided
          try {
            new URL(value);
            return '';
          } catch (e) {
            return 'URL inválida';
          }
        }
        return '';  // Empty URL is valid
      case 'productImage':
        return !value ? 'Imagem do produto é obrigatória' : '';
      case 'productFile':
        return !value ? 'Arquivo do produto é obrigatório' : '';
      default:
        return '';
    }
  };

  const handleUpsellToggle = (upsellId) => {
    setSelectedUpsells(prev => {
      if (prev.includes(upsellId)) {
        return prev.filter(id => id !== upsellId);
      }
      return [...prev, upsellId];
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCpfToggle = () => {
    setShowCpfField(!showCpfField);
  };

  const handleNameToggle = () => {
    setShowNameField(!showNameField);
  };

  const handleEmailToggle = () => {
    setShowEmailField(!showEmailField);
  };

  const handlePhoneToggle = () => {
    setShowPhoneField(!showPhoneField);
  };

  useEffect(() => {
    setFormData(initialData || defaultFormData);
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'price') {
      // Remove non-numeric characters and convert to number
      const numericValue = value.replace(/[^\d]/g, '');
      const floatValue = (parseFloat(numericValue) / 100).toFixed(2);
      setFormData((prev) => ({
        ...prev,
        [name]: floatValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (key !== 'picture_url' && key !== 'file_url') {  // Skip validation for optional fields
        newErrors[key] = validateField(key, formData[key]);
      }
    });

    // Check if there are any errors
    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    // Validate payment methods
    if (!formData.payment_methods?.length) {
      setPaymentMethodError('Selecione pelo menos um método de pagamento');
      return;
    }

    setPaymentMethodError('');
    
    // Map the fields correctly
    const formDataToSubmit = {
      ...formData,
      seller_email: formData.support_email,
      seller_product_name: formData.seller_name
    };
    
    handleSubmit(formDataToSubmit);
  };

  // Updated templates with availability status
  const templates = [
    { value: 'clavoalfa', label: 'Clavo Alfa' },
    { value: 'clavobeta', label: 'Clavo Beta', disabled: true },
  ];

  const formatPrice = (value) => {
    if (!value) return '';
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  const ToggleField = ({ label, value, onToggle }) => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 2,
      mb: 2 
    }}>
      <Typography sx={{ 
        color: '#6B7280',
        fontSize: '14px',
        fontWeight: 500,
        ...interFont
      }}>
        {label}
      </Typography>
      <Box sx={{ 
        bgcolor: '#F3F4F6',
        borderRadius: '12px',
        p: '4px',
        display: 'inline-flex',
        gap: '4px'
      }}>
        <Button
          onClick={() => onToggle({ target: { checked: true } })}
          sx={{
            ...interFont,
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '14px',
            minHeight: 'unset',
            height: '32px',
            padding: '0 16px',
            borderRadius: '8px',
            color: value ? '#18a34a' : '#6B7280',
            bgcolor: value ? '#dcfce8' : 'transparent',
            boxShadow: value ? '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' : 'none',
            '&:hover': {
              bgcolor: value ? '#dcfce8' : 'rgba(255, 255, 255, 0.7)',
            }
          }}
        >
          Mostrar
        </Button>
        <Button
          onClick={() => onToggle({ target: { checked: false } })}
          sx={{
            ...interFont,
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '14px',
            minHeight: 'unset',
            height: '32px',
            padding: '0 16px',
            borderRadius: '8px',
            color: !value ? '#dc2626' : '#6B7280',
            bgcolor: !value ? '#fee2e2' : 'transparent',
            boxShadow: !value ? '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' : 'none',
            '&:hover': {
              bgcolor: !value ? '#fee2e2' : 'rgba(255, 255, 255, 0.7)',
            }
          }}
        >
          Ocultar
        </Button>
      </Box>
    </Box>
  );

  const [dragActive, setDragActive] = useState(false);
  const [dragActiveFile, setDragActiveFile] = useState(false);

  const handleDrag = (e, setDragState) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragState(true);
    } else if (e.type === "dragleave") {
      setDragState(false);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'image') {
      setDragActive(false);
    } else {
      setDragActiveFile(false);
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (type === 'image') {
        handleImageChange(e.dataTransfer.files[0]);
      } else {
        handleFileChange(e.dataTransfer.files[0]);
      }
    }
  };

  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          picture_url: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (file) => {
    if (file) {
      setFormData(prev => ({
        ...prev,
        file_url: file.name
      }));
    }
  };

  const handleFileSelect = (e, type) => {
    if (e.target.files && e.target.files[0]) {
      if (type === 'image') {
        handleImageChange(e.target.files[0]);
      } else {
        handleFileChange(e.target.files[0]);
      }
    }
  };

  const renderFormFields = useCallback(() => (
    <Grid container spacing={2.5} sx={{ px: 2.5 }}>
      <Grid item container xs={12} md={4} spacing={2.5}>
        <Grid item xs={12}>
          <Typography 
            variant="body2" 
            sx={{ 
              ...interFont, 
              color: colors.text.secondary,
              mb: 1,
              fontWeight: 500 
            }}
          >
            Imagem do produto
          </Typography>
          <Box
            sx={{
              border: `1px dashed ${errors.productImage ? '#ef4444' : colors.border}`,
              borderRadius: '10px',
              p: 3,
              minHeight: '216px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              cursor: 'pointer',
              backgroundColor: dragActive ? 'rgba(25, 118, 210, 0.04)' : 'transparent',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: errors.productImage ? '#ef4444' : colors.primary,
                backgroundColor: 'rgba(25, 118, 210, 0.04)'
              }
            }}
            onDragEnter={(e) => handleDrag(e, setDragActive)}
            onDragLeave={(e) => handleDrag(e, setDragActive)}
            onDragOver={(e) => handleDrag(e, setDragActive)}
            onDrop={(e) => handleDrop(e, 'image')}
            onClick={() => document.getElementById('image-upload').click()}
          >
            {formData.picture_url ? (
              <Box
                component="img"
                src={formData.picture_url}
                alt="Product"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '200px',
                  objectFit: 'contain'
                }}
              />
            ) : (
              <>
                <CloudUploadIcon sx={{ fontSize: 48, color: errors.productImage ? '#ef4444' : 'grey.500', mb: 1 }} />
                <Typography variant="body1" sx={{ mb: 0.5 }}>
                  Arraste e solte uma imagem ou
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ color: errors.productImage ? '#ef4444' : 'primary.main', cursor: 'pointer' }}
                >
                  selecione um arquivo
                </Typography>
              </>
            )}
            <input
              type="file"
              id="image-upload"
              accept="image/*"
              onChange={(e) => handleFileSelect(e, 'image')}
              style={{ display: 'none' }}
            />
          </Box>
          {errors.productImage && (
            <Typography 
              variant="caption" 
              color="textSecondary" 
              sx={{ 
                color: '#ef4444', 
                fontSize: '12px',
                mt: 0.5,
                fontFamily: interFont.fontFamily
              }}
            >
              {errors.productImage}
            </Typography>
          )}
          <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
            Sugerimos PNG ou JPG com 650px x 650px (1:1).
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography 
            variant="subtitle1"
            sx={{ 
              ...interFont, 
              color: colors.text.secondary,
              mb: '3px',
              fontSize: '14px',
              fontWeight: 500 
            }}
          >
            Arquivo do produto
          </Typography>
          <Box
            sx={{
              border: `1px dashed ${errors.productFile ? '#ef4444' : colors.border}`,
              borderRadius: '10px',
              p: 3,
              minHeight: '87px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              cursor: 'pointer',
              backgroundColor: dragActiveFile ? 'rgba(25, 118, 210, 0.04)' : 'transparent',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: errors.productFile ? '#ef4444' : colors.primary,
                backgroundColor: 'rgba(25, 118, 210, 0.04)'
              }
            }}
            onDragEnter={(e) => handleDrag(e, setDragActiveFile)}
            onDragLeave={(e) => handleDrag(e, setDragActiveFile)}
            onDragOver={(e) => handleDrag(e, setDragActiveFile)}
            onDrop={(e) => handleDrop(e, 'file')}
            onClick={() => document.getElementById('file-upload').click()}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CloudUploadIcon sx={{ fontSize: 24, color: errors.productFile ? '#ef4444' : 'grey.500' }} />
              <Typography
                variant="body1"
                sx={{
                  color: errors.productFile ? '#ef4444' : 'primary.main',
                  cursor: 'pointer'
                }}
              >
                {formData.file_url || 'selecione um arquivo'}
              </Typography>
            </Box>
            <input
              type="file"
              id="file-upload"
              onChange={(e) => handleFileSelect(e, 'file')}
              style={{ display: 'none' }}
            />
          </Box>
          {errors.productFile && (
            <Typography 
              variant="caption" 
              sx={{ 
                color: '#ef4444',
                fontSize: '12px',
                mt: 0.5,
                fontFamily: interFont.fontFamily
              }}
            >
              {errors.productFile}
            </Typography>
          )}
          <Typography 
            variant="body2" 
            sx={{ 
              mt: 2, 
              color: '#6B7280',
              ...interFont,
              fontSize: '12px',
            }}
          >
            Este é o arquivo que será enviado ao cliente após a confirmação da venda.
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <Typography 
          variant="body2" 
          sx={{ 
            ...interFont, 
            color: colors.text.secondary,
            mb: 1,
            fontWeight: 500 
          }}
        >
          Nome do produto
        </Typography>
        <TextField
          fullWidth
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          placeholder="Digite o nome"
          error={!!errors.title}
          helperText={errors.title}
          FormHelperTextProps={{
            sx: {
              color: '#ef4444',
              marginLeft: '0',
              fontSize: '12px',
              fontFamily: interFont.fontFamily
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
              '& fieldset': {
                borderColor: colors.border,
              },
              '&:hover fieldset': {
                borderColor: colors.primary,
              },
              '&.Mui-focused fieldset': {
                borderColor: colors.primary,
              },
            },
            '& input': {
              ...interFont,
              padding: '12px 14px',
              color: colors.text.primary,
              '&::placeholder': {
                color: colors.text.secondary,
                opacity: 1,
              },
            },
            mb: 2
          }}
        />

        <Typography 
          variant="body2" 
          sx={{ 
            ...interFont, 
            color: colors.text.secondary,
            mb: 1,
            fontWeight: 500 
          }}
        >
          Descrição
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Descrição apresentada no checkout"
          error={!!errors.description}
          helperText={errors.description}
          FormHelperTextProps={{
            sx: {
              color: '#ef4444',
              marginLeft: '0',
              fontSize: '12px',
              fontFamily: interFont.fontFamily
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
              '& fieldset': {
                borderColor: colors.border,
              },
              '&:hover fieldset': {
                borderColor: colors.primary,
              },
              '&.Mui-focused fieldset': {
                borderColor: colors.primary,
              },
            },
            '& textarea': {
              ...interFont,
              color: colors.text.primary,
              '&::placeholder': {
                color: colors.text.secondary,
                opacity: 1,
              },
            },
          }}
        />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Typography 
              variant="body2" 
              sx={{ 
                ...interFont, 
                color: colors.text.secondary,
                mb: 1,
                fontWeight: 500
              }}
            >
              Preço
            </Typography>
            <TextField
              fullWidth
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              placeholder="R$ 0,00"
              error={!!errors.price}
              helperText={errors.price}
              FormHelperTextProps={{
                sx: {
                  color: '#ef4444',
                  marginLeft: '0',
                  fontSize: '12px',
                  fontFamily: interFont.fontFamily
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.primary,
                  },
                },
                '& input': {
                  ...interFont,
                  padding: '12px 14px',
                  color: colors.text.primary,
                  '&::placeholder': {
                    color: colors.text.secondary,
                    opacity: 1,
                  },
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography 
              variant="body2" 
              sx={{ 
                ...interFont, 
                color: colors.text.secondary,
                mb: 1,
                fontWeight: 500
              }}
            >
              Preço final com desconto
            </Typography>
            <TextField
              fullWidth
              name="promotional_price"
              value={formData.promotional_price}
              onChange={handleInputChange}
              placeholder="R$ 0,00"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.primary,
                  },
                },
                '& input': {
                  ...interFont,
                  padding: '12px 14px',
                  color: colors.text.primary,
                  '&::placeholder': {
                    color: colors.text.secondary,
                    opacity: 1,
                  },
                }
              }}
            />
          </Grid>
        </Grid>

        <Typography 
          variant="body2" 
          sx={{ 
            ...interFont, 
            color: colors.text.secondary,
            mb: 1,
            fontWeight: 500,
            mt: 2
          }}
        >
          Aceitar pagamentos:
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box
            onClick={() => {
              setPaymentMethodError('');
              const newMethods = formData.payment_methods?.includes('credit_card')
                ? formData.payment_methods.filter(m => m !== 'credit_card')
                : [...(formData.payment_methods || []), 'credit_card'];
              handleInputChange({ target: { name: 'payment_methods', value: newMethods } });
            }}
            sx={{
              px: '20px',
              py: '10px',
              borderRadius: '10px',
              border: '2px solid',
              borderColor: formData.payment_methods?.includes('credit_card') ? '#22c55e' : '#E5E7EB',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: formData.payment_methods?.includes('credit_card') ? '#22c55e' : '#F9FAFB',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: '#22c55e',
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CreditCard size={20} color={formData.payment_methods?.includes('credit_card') ? '#ffffff' : '#6B7280'} />
              <Typography
                sx={{
                  color: formData.payment_methods?.includes('credit_card') ? '#ffffff' : '#6B7280',
                  fontWeight: 500
                }}
              >
                Cartão de Crédito
              </Typography>
            </Box>
          </Box>

          <Box
            onClick={() => {
              setPaymentMethodError('');
              const newMethods = formData.payment_methods?.includes('pix')
                ? formData.payment_methods.filter(m => m !== 'pix')
                : [...(formData.payment_methods || []), 'pix'];
              handleInputChange({ target: { name: 'payment_methods', value: newMethods } });
            }}
            sx={{
              px: '20px',
              py: '10px',
              borderRadius: '10px',
              border: '2px solid',
              borderColor: formData.payment_methods?.includes('pix') ? '#22c55e' : '#E5E7EB',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: formData.payment_methods?.includes('pix') ? '#22c55e' : '#F9FAFB',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: '#22c55e',
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                sx={{
                  color: formData.payment_methods?.includes('pix') ? '#ffffff' : '#6B7280',
                  fontWeight: 500
                }}
              >
                PIX
              </Typography>
            </Box>
          </Box>
        </Box>
        {paymentMethodError && (
          <Typography 
            variant="caption" 
            sx={{ 
              color: 'error.main',
              mt: 1,
              display: 'block'
            }}
          >
            {paymentMethodError}
          </Typography>
        )}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Typography 
              variant="body2" 
              sx={{ 
                ...interFont, 
                color: colors.text.secondary,
                mb: 1,
                fontWeight: 500 
              }}
            >
              Email de suporte
            </Typography>
            <TextField
              fullWidth
              name="support_email"
              value={formData.support_email}
              onChange={handleInputChange}
              placeholder="Digite o email de suporte"
              error={!!errors.support_email}
              helperText={errors.support_email}
              FormHelperTextProps={{
                sx: {
                  color: '#ef4444',
                  marginLeft: '0',
                  fontSize: '12px',
                  fontFamily: interFont.fontFamily
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.primary,
                  },
                },
                '& input': {
                  ...interFont,
                  padding: '12px 14px',
                  color: colors.text.primary,
                  '&::placeholder': {
                    color: colors.text.secondary,
                    opacity: 1,
                  },
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography 
              variant="body2" 
              sx={{ 
                ...interFont, 
                color: colors.text.secondary,
                mb: 1,
                fontWeight: 500 
              }}
            >
              Nome do vendedor
            </Typography>
            <TextField
              fullWidth
              name="seller_name"
              value={formData.seller_name}
              onChange={handleInputChange}
              placeholder="Digite o nome do vendedor"
              error={!!errors.seller_name}
              helperText={errors.seller_name}
              FormHelperTextProps={{
                sx: {
                  color: '#ef4444',
                  marginLeft: '0',
                  fontSize: '12px',
                  fontFamily: interFont.fontFamily
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.primary,
                  },
                },
                '& input': {
                  ...interFont,
                  padding: '12px 14px',
                  color: colors.text.primary,
                  '&::placeholder': {
                    color: colors.text.secondary,
                    opacity: 1,
                  },
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ), [formData, colors, handleInputChange, dragActive, dragActiveFile]);

  const formatCurrency = (value) => {
    if (!value) return '';
    // Remove any non-digit character
    const number = value.replace(/\D/g, '');
    // Convert to number and divide by 100 to get decimal places
    const formatted = (number / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    return formatted;
  };

  const handleUpsellInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'price' || name === 'discount_price') {
      // Remove any non-digit character and store as number
      const numericValue = value.replace(/\D/g, '');
      
      setUpsellForm(prev => ({
        ...prev,
        [name]: numericValue
      }));

      // Clear error when user starts typing
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));

      // Validate prices when discount_price changes
      if (name === 'discount_price') {
        const price = parseInt(upsellForm.price) || 0;
        const discountPrice = parseInt(numericValue) || 0;
        
        if (discountPrice >= price && price !== 0) {
          setFormErrors(prev => ({ ...prev, discount_price: 'O preço final com desconto deve ser menor que o preço original' }));
        }
      }
      // Also validate when price changes
      if (name === 'price') {
        const price = parseInt(numericValue) || 0;
        const discountPrice = parseInt(upsellForm.discount_price) || 0;
        
        if (discountPrice >= price && price !== 0) {
          setFormErrors(prev => ({ ...prev, discount_price: 'O preço final com desconto deve ser menor que o preço original' }));
        }
      }
    } else {
      setUpsellForm(prev => ({
        ...prev,
        [name]: value
      }));

      // Clear error when user starts typing
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleUpsellSubmit = (e) => {
    e.preventDefault();
    
    // Validate required fields
    const newErrors = {};
    if (!upsellForm.name.trim()) {
      newErrors.name = 'Nome é obrigatório';
    }
    if (!upsellForm.price || isNaN(upsellForm.price) || parseFloat(upsellForm.price) <= 0) {
      newErrors.price = 'Preço é obrigatório e deve ser maior que zero';
    }
    if (!upsellForm.image_url.trim()) {
      newErrors.image_url = 'Imagem do produto é obrigatória';
    }
    if (!upsellForm.file_url.trim()) {
      newErrors.file_url = 'Arquivo do produto é obrigatório';
    }
    if (upsellForm.discount_price) {
      const price = parseFloat(upsellForm.price) || 0;
      const discountPrice = parseFloat(upsellForm.discount_price) || 0;
      
      if (isNaN(discountPrice) || discountPrice <= 0) {
        newErrors.discount_price = 'Preço com desconto deve ser maior que zero';
      } else if (discountPrice >= price) {
        newErrors.discount_price = 'Preço com desconto deve ser menor que o preço original';
      }
    }

    setFormErrors(newErrors);

    // If there are errors, don't submit and don't close modal
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (editingUpsellId) {
      setUpsells(prevUpsells => 
        prevUpsells.map(upsell => 
          upsell.id === editingUpsellId 
            ? { ...upsellForm, id: editingUpsellId }
            : upsell
        )
      );
    } else {
      setUpsells(prevUpsells => [
        ...prevUpsells,
        {
          ...upsellForm,
          id: Date.now(),
        }
      ]);
    }

    // Reset form and close modal
    setUpsellForm({
      name: '',
      description: '',
      image_url: '',
      file_url: '',
      price: '',
      discount_price: '',
    });
    setFormErrors({});
    setEditingUpsellId(null);
    setOpenUpsellModal(false);
  };

  const handleUpsellFileSelect = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (type === 'image') {
        setUpsellForm(prev => ({
          ...prev,
          image_url: file.name
        }));
        // Clear image error when file is selected
        setFormErrors(prev => ({
          ...prev,
          image_url: ''
        }));
      } else {
        setUpsellForm(prev => ({
          ...prev,
          file_url: file.name
        }));
        // Clear file error when file is selected
        setFormErrors(prev => ({
          ...prev,
          file_url: ''
        }));
      }
    }
  };

  const handleUpsellSelect = (upsellId) => {
    setSelectedUpsells(prev => {
      if (prev.includes(upsellId)) {
        return prev.filter(id => id !== upsellId);
      }
      return [...prev, upsellId];
    });
  };

  const handleEditUpsell = (upsell) => {
    setUpsellForm({
      name: upsell.name,
      description: upsell.description || '',
      image_url: upsell.image_url,
      file_url: upsell.file_url,
      price: upsell.price,
      discount_price: upsell.discount_price,
    });
    setEditingUpsellId(upsell.id);
    setOpenUpsellModal(true);
  };

  const handleCreateUpsell = () => {
    // Reset form to clean values
    setUpsellForm({
      name: '',
      description: '',
      image_url: '',
      file_url: '',
      price: '',
      discount_price: '',
    });
    setFormErrors({});
    setEditingUpsellId(null);
    setOpenUpsellModal(true);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 900, mx: 'auto', p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ ...interFont, fontWeight: 600, justifySelf: 'center'
 }}>
          {initialData ? 'Editar Produto' : 'Adicionar Novo Produto'}
        </Typography>
      </Box>
      <form onSubmit={handleFormSubmit} style={{ width: '100%' }} noValidate>
        <Box sx={{ 
          borderBottom: 0, 
          bgcolor: '#F3F4F6',
          borderRadius: '16px',
          p: '4px',
          mb: 3,
          width: 'fit-content',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            sx={{
              minHeight: 'unset',
              '& .MuiTabs-flexContainer': {
                gap: '4px'
              },
              '& .MuiTab-root': {
                ...interFont,
                textTransform: 'none',
                fontWeight: 500,
                color: '#6B7280',
                fontSize: '14px',
                minHeight: 'unset',
                height: '40px',
                padding: '0 24px',
                borderRadius: '12px',
                transition: 'all 0.2s ease',
                '&:nth-of-type(3)': { // Black Mode tab (third tab)
                  '&.Mui-selected': {
                    color: '#fff',
                    bgcolor: '#111827',
                  },
                  '&:hover': {
                    color: '#fff',
                    bgcolor: 'rgba(0, 0, 0, 0.8)',
                  }
                },
                '&:not(:nth-of-type(3))': { // Other tabs (first and second)
                  '&.Mui-selected': {
                    color: '#111827',
                    bgcolor: '#fff',
                  },
                  '&:hover': {
                    color: '#111827',
                    bgcolor: 'rgba(255, 255, 255, 0.7)',
                  }
                },
                '&.Mui-selected': {
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                  fontWeight: 600
                }
              },
              '& .MuiTabs-indicator': {
                display: 'none'
              },
            }}
          >
            <Tab label="Produto" />
            <Tab label="Upsell" />
            <Tab label="Black Mode" />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          {renderFormFields()}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box sx={{ 
            bgcolor: '#F8F9FA',
            borderRadius: '12px',
            p: 2,
            minHeight: 'auto'
          }}>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              mb: 2,
              alignItems: 'center'
            }}>
              <TextField
                size="small"
                placeholder="Buscar upsell..."
                value={searchUpsell}
                onChange={(e) => setSearchUpsell(e.target.value)}
                sx={{
                  flex: 1,
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white',
                    borderRadius: '12px',
                    padding: '12px',
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: 0,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      <Search size={20} color="#9CA3AF" />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                onClick={handleCreateUpsell}
                sx={{
                  height: '44px',
                  bgcolor: '#29ae60',
                  color: '#fff',
                  borderRadius: '10px',
                  px: '16px',
                  py: '6px',
                  '&:hover': {
                    bgcolor: '#22c55e',
                  },
                  textTransform: 'none',
                  fontWeight: 500,
                  ...interFont,
                }}
              >
                Criar upsell
              </Button>
            </Box>
            {upsells.length === 0 && (
              <Typography 
                sx={{ 
                  color: '#6B7280',
                  fontSize: '14px',
                  mt: 3,
                  textAlign: 'center'
                }}
              >
                Todos os upssels criados apareceram aqui. Crie o seu primeiro
              </Typography>
            )}
            {upsells.length > 0 && (
              <Box sx={{ mt: 3 }}>
                {upsells.map(upsell => (
                  <Box
                    key={upsell.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      p: '20px',
                      borderRadius: '8px',
                      mb: 1,
                      bgcolor: selectedUpsells.includes(upsell.id) ? '#21c55f0a' : '#fff',
                      border: selectedUpsells.includes(upsell.id) ? 'solid 1px #21c55f42' : '1px solid #fff',
                    }}
                  >
                    <Checkbox
                      checked={selectedUpsells.includes(upsell.id)}
                      onChange={() => handleUpsellSelect(upsell.id)}
                      sx={{
                        color: '#E5E7EB',
                        padding: 0,
                        '&.Mui-checked': {
                          color: '#22c55e',
                        }
                      }}
                    />
                    <Box
                      component="img"
                      src={upsell.image_url}
                      alt={upsell.name}
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '4px',
                        objectFit: 'cover',
                        bgcolor: '#F3F4F6',
                      }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography 
                        sx={{ 
                          fontWeight: 500, 
                          color: '#374151',
                          fontSize: '14px',
                          lineHeight: '20px',
                          mb: '4px'
                        }}
                      >
                        {upsell.name}
                      </Typography>
                      <Typography 
                        sx={{ 
                          color: '#6B7280', 
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}
                      >
                        {upsell.description || 'OfficeFurniture Premium products'}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right', minWidth: 'fit-content' }}>
                      <Typography 
                        sx={{ 
                          color: '#9CA3AF',
                          fontSize: '12px',
                          lineHeight: '16px',
                          textDecoration: 'line-through',
                          mb: '2px'
                        }}
                      >
                        {formatCurrency(upsell.price)}
                      </Typography>
                      <Typography 
                        sx={{ 
                          color: '#374151',
                          fontSize: '14px',
                          lineHeight: '20px',
                          fontWeight: 500
                        }}
                      >
                        {formatCurrency(upsell.discount_price)}
                      </Typography>
                    </Box>
                    <Box 
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        ml: 2
                      }}
                    >
                      <Button
                        sx={{
                          color: '#000',
                          fontSize: '12px',
                          lineHeight: '20px',
                          fontWeight: 500,
                          padding: 0,
                          minWidth: 'auto',
                          '&:hover': {
                            background: 'none',
                            color: '#374151'
                          }
                        }}
                        onClick={() => handleEditUpsell(upsell)}
                      >
                        Editar
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Box sx={{ 
            p: 2,
            minHeight: 'auto'
          }}>
            <Typography
              sx={{
                ...interFont,
                fontSize: '16px',
                fontWeight: 600,
                color: '#111827',
                mb: 3
              }}
            >
              Identificação do cliente
            </Typography>
            <ToggleField 
              label={`${!formData.black_mode_name_enabled ? 'Ocultar' : 'Mostrar'} campo de Nome Completo:`}
              value={!formData.black_mode_name_enabled}
              onToggle={(e) => {
                const newValue = !e.target.checked;
                setFormData(prev => ({
                  ...prev,
                  black_mode_name_enabled: newValue
                }));
              }}
            />
            <ToggleField 
              label={`${!formData.black_mode_email_enabled ? 'Ocultar' : 'Mostrar'} campo de E-mail:`}
              value={!formData.black_mode_email_enabled}
              onToggle={(e) => {
                const newValue = !e.target.checked;
                setFormData(prev => ({
                  ...prev,
                  black_mode_email_enabled: newValue
                }));
              }}
            />
            <ToggleField 
              label={`${!formData.black_mode_cpf_enabled ? 'Ocultar' : 'Mostrar'} campo de CPF:`}
              value={!formData.black_mode_cpf_enabled}
              onToggle={(e) => {
                const newValue = !e.target.checked;
                setFormData(prev => ({
                  ...prev,
                  black_mode_cpf_enabled: newValue
                }));
              }}
            />
            <ToggleField 
              label={`${!formData.black_mode_phone_enabled ? 'Ocultar' : 'Mostrar'} campo de Telefone (whatsapp):`}
              value={!formData.black_mode_phone_enabled}
              onToggle={(e) => {
                const newValue = !e.target.checked;
                setFormData(prev => ({
                  ...prev,
                  black_mode_phone_enabled: newValue
                }));
              }}
            />
            <ToggleField 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ ...interFont }}>
                  Puxar dados pelo CPF
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    ml: 1,
                    color: colors.text.secondary,
                    ...interFont
                  }}
                >
                  (Nome e data de nascimento)
                </Typography>
              </Box>
              }
              value={formData.fetch_cpf_data}
              onToggle={(e) => {
                const newValue = e.target.checked;
                setFormData(prev => ({
                  ...prev,
                  fetch_cpf_data: newValue
                }));
              }}
            />
          </Box>
        </TabPanel>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3 }}>
          <Button onClick={handleClose} sx={{ color: colors.textSecondary }}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              height: '44px',
              bgcolor: '#29ae60',
              color: '#fff',
              borderRadius: '10px',
              px: '16px',
              py: '6px',
              fontSize: '16px',
              '&:hover': {
                bgcolor: '#22c55e',
              },
              textTransform: 'none',
              fontWeight: 500,
              ...interFont,
            }}
          >
            {initialData ? 'Salvar alterações' : 'Adicionar Produto'}
          </Button>
        </Box>
      </form>
      <Dialog 
        open={openUpsellModal} 
        onClose={() => {
          setOpenUpsellModal(false);
          setFormErrors({});
          setUpsellForm({
            name: '',
            description: '',
            image_url: '',
            file_url: '',
            price: '',
            discount_price: '',
          });
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
            bgcolor: 'background.paper',
          }
        }}
      >
        <DialogTitle sx={{ 
          p: '20px',
          color: '#111827',
          fontSize: '18px',
          fontWeight: 600,
          ...interFont,
        }}>
          {editingUpsellId ? 'Editar Upsell' : 'Criar Upsell'}
        </DialogTitle>
        <form onSubmit={handleUpsellSubmit} noValidate>
          <DialogContent sx={{ p: '20px', pt: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              {/* First Row */}
              <Box sx={{ display: 'flex', gap: '24px' }}>
                {/* Left Column - Image */}
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ 
                      color: '#374151',
                      mb: '8px',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Imagem do produto
                  </Typography>
                  <Box
                    sx={{
                      border: `1px dashed ${formErrors.image_url ? '#ef4444' : '#E5E7EB'}`,
                      borderRadius: '8px',
                      p: 3,
                      minHeight: '216px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      backgroundColor: '#fff',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        borderColor: formErrors.image_url ? '#ef4444' : '#9CA3AF',
                      }
                    }}
                    onDragEnter={(e) => handleDrag(e, setDragActiveUpsell)}
                    onDragLeave={(e) => handleDrag(e, setDragActiveUpsell)}
                    onDragOver={(e) => handleDrag(e, setDragActiveUpsell)}
                    onDrop={(e) => handleDrop(e, 'upsell_image')}
                    onClick={() => document.getElementById('upsell-image-upload').click()}
                  >
                    {upsellForm.image_url ? (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-start', 
                        gap: 1,
                        width: '100%',
                        maxWidth: '100%'
                      }}>
                        <CloudUploadIcon sx={{ 
                          fontSize: 20, 
                          color: '#29ae60', 
                          flexShrink: 0,
                          mt: 0.5 
                        }} />
                        <Typography 
                          sx={{ 
                            color: '#29ae60',
                            wordBreak: 'break-word',
                            flex: 1,
                          }}
                        >
                          {upsellForm.image_url}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <CloudUploadIcon sx={{ fontSize: 48, color: '#9CA3AF', mb: 2 }} />
                        <Typography sx={{ color: '#374151', mb: 1, textAlign: 'center' }}>
                          Arraste e solte uma imagem ou
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ color: '#22c55e', cursor: 'pointer' }}
                        >
                          selecione um arquivo
                        </Typography>
                      </>
                    )}
                    <input
                      type="file"
                      id="upsell-image-upload"
                      onChange={(e) => handleUpsellFileSelect(e, 'image')}
                      style={{ display: 'none' }}
                      accept="image/png,image/jpeg"
                    />
                  </Box>
                  {formErrors.image_url && (
                    <Typography 
                      sx={{ 
                        color: '#ef4444',
                        fontSize: '12px',
                        mt: 0.5,
                        ml: 0
                      }}
                    >
                      {formErrors.image_url}
                    </Typography>
                  )}
                  <Typography variant="caption" sx={{ color: '#6B7280', mt: 1, display: 'block' }}>
                    Sugerimos PNG ou JPG com 650px x 650px (1:1).
                  </Typography>
                </Box>

                {/* Right Column - Name and Description */}
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ mb: 3 }}>
                    <Typography 
                      variant="subtitle1"
                      sx={{ 
                        color: '#374151',
                        mb: '8px',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      Nome do produto
                    </Typography>
                    <TextField
                      fullWidth
                      name="name"
                      placeholder="Digite o nome"
                      value={upsellForm.name}
                      onChange={handleUpsellInputChange}
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                      inputProps={{ 'aria-required': true }}
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          bgcolor: '#fff',
                          borderRadius: '8px',
                          '& fieldset': {
                            borderColor: formErrors.name ? '#ef4444' : '#E5E7EB',
                          },
                          '&:hover fieldset': {
                            borderColor: formErrors.name ? '#ef4444' : '#22c55e',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: formErrors.name ? '#ef4444' : '#22c55e',
                          }
                        },
                        '& .MuiFormHelperText-root': {
                          color: '#ef4444',
                          marginLeft: 0
                        }
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography 
                      variant="subtitle1"
                      sx={{ 
                        color: '#374151',
                        mb: '8px',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      Descrição
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      name="description"
                      placeholder="Descrição apresentada no checkout"
                      value={upsellForm.description}
                      onChange={handleUpsellInputChange}
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          bgcolor: '#fff',
                          borderRadius: '8px',
                          '& fieldset': {
                            borderColor: '#E5E7EB',
                          },
                          '&:hover fieldset': {
                            borderColor: '#22c55e',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#22c55e',
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              {/* Second Row */}
              <Box sx={{ display: 'flex', gap: '24px' }}>
                {/* Left Column - File */}
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ 
                      color: '#374151',
                      mb: '8px',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Arquivo do produto
                  </Typography>
                  <Box
                    sx={{
                      border: `1px dashed ${formErrors.file_url ? '#ef4444' : '#E5E7EB'}`,
                      borderRadius: '8px',
                      p: 3,
                      minHeight: '87px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      backgroundColor: '#fff',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        borderColor: formErrors.file_url ? '#ef4444' : '#9CA3AF',
                      }
                    }}
                    onDragEnter={(e) => handleDrag(e, setDragActiveUpsellFile)}
                    onDragLeave={(e) => handleDrag(e, setDragActiveUpsellFile)}
                    onDragOver={(e) => handleDrag(e, setDragActiveUpsellFile)}
                    onDrop={(e) => handleDrop(e, 'upsell_file')}
                    onClick={() => document.getElementById('upsell-file-upload').click()}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      gap: 1,
                      width: '100%',
                      maxWidth: '100%'
                    }}>
                      <CloudUploadIcon sx={{ 
                        fontSize: 24, 
                        color: upsellForm.file_url ? '#29ae60' : '#9CA3AF', 
                        flexShrink: 0,
                        mt: 0.5 
                      }} />
                      <Typography
                        sx={{ 
                          color: '#22c55e',
                          cursor: 'pointer',
                          wordBreak: 'break-word',
                          flex: 1,
                        }}
                      >
                        {upsellForm.file_url || 'selecione um arquivo'}
                      </Typography>
                    </Box>
                    <input
                      type="file"
                      id="upsell-file-upload"
                      onChange={(e) => handleUpsellFileSelect(e, 'file')}
                      style={{ display: 'none' }}
                    />
                  </Box>
                  {formErrors.file_url && (
                    <Typography 
                      sx={{ 
                        color: '#ef4444',
                        fontSize: '12px',
                        mt: 0.5,
                        ml: 0
                      }}
                    >
                      {formErrors.file_url}
                    </Typography>
                  )}
                  <Typography variant="caption" sx={{ color: '#6B7280', mt: 1, display: 'block' }}>
                    Este é o arquivo que será enviado ao cliente após a confirmação da venda.
                  </Typography>
                </Box>

                {/* Right Column - Prices */}
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ mb: 3 }}>
                    <Typography 
                      variant="subtitle1"
                      sx={{ 
                        color: '#374151',
                        mb: '8px',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      Preço
                    </Typography>
                    <TextField
                      fullWidth
                      name="price"
                      placeholder="0,00"
                      value={formatCurrency(upsellForm.price)}
                      onChange={handleUpsellInputChange}
                      error={!!formErrors.price}
                      helperText={formErrors.price}
                      inputProps={{ 'aria-required': true }}
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          bgcolor: '#fff',
                          borderRadius: '8px',
                          '& fieldset': {
                            borderColor: formErrors.price ? '#ef4444' : '#E5E7EB',
                          },
                          '&:hover fieldset': {
                            borderColor: formErrors.price ? '#ef4444' : '#22c55e',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: formErrors.price ? '#ef4444' : '#22c55e',
                          }
                        },
                        '& .MuiFormHelperText-root': {
                          color: '#ef4444',
                          marginLeft: 0
                        }
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography 
                      variant="subtitle1"
                      sx={{ 
                        color: '#374151',
                        mb: '8px',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      Preço final com desconto
                    </Typography>
                    <TextField
                      fullWidth
                      name="discount_price"
                      placeholder="0,00"
                      value={formatCurrency(upsellForm.discount_price)}
                      onChange={handleUpsellInputChange}
                      error={!!formErrors.discount_price}
                      helperText={formErrors.discount_price}
                      inputProps={{ 'aria-required': false }}
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          bgcolor: '#fff',
                          borderRadius: '8px',
                          '& fieldset': {
                            borderColor: formErrors.discount_price ? '#ef4444' : '#E5E7EB',
                          },
                          '&:hover fieldset': {
                            borderColor: formErrors.discount_price ? '#ef4444' : '#22c55e',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: formErrors.discount_price ? '#ef4444' : '#22c55e',
                          }
                        },
                        '& .MuiFormHelperText-root': {
                          color: '#ef4444',
                          marginLeft: 0
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: '20px', pt: 0 }}>
            <Button
              onClick={() => {
                setOpenUpsellModal(false);
                setFormErrors({});
                setUpsellForm({
                  name: '',
                  description: '',
                  image_url: '',
                  file_url: '',
                  price: '',
                  discount_price: '',
                });
              }}
              sx={{
                height: '44px',
                bgcolor: '#f3f4f6',
                color: '#6B7280',
                borderRadius: '10px',
                px: '16px',
                py: '6px',
                '&:hover': {
                  bgcolor: '#e5e7eb'
                },
                textTransform: 'none',
                fontWeight: 500,
                ...interFont,
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                height: '44px',
                bgcolor: '#29ae60',
                color: '#fff',
                borderRadius: '10px',
                px: '16px',
                py: '6px',
                fontSize: '16px',
                '&:hover': {
                  bgcolor: '#22c55e',
                },
                textTransform: 'none',
                fontWeight: 500,
                ...interFont,
              }}
            >
              {editingUpsellId ? 'Salvar alterações' : 'Criar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

ProductForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

function Products() {
  const theme = useTheme();
  const colors = getThemeColors(theme);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [viewMode, setViewMode] = useState('grid');
  const [value, setValue] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const navigate = useNavigate();

  const handleNewProduct = () => {
    navigate('/products/new');
  };

  const handleToggleActive = useCallback(async (productId, currentStatus, product) => {
    try {
      // Only send the active status update
      const response = await api.patch(`/products/${productId}/`, {
        active: !currentStatus
      });
      
      // Update local state with the response data
      setProducts(prevProducts =>
        prevProducts.map(p =>
          p.id === productId ? { ...product, active: !currentStatus } : p
        )
      );
    } catch (err) {
      if (err.response?.status === 401) {
        // logout();
      } else {
        console.error('Error updating product status:', err.response?.data || err);
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('Input changed:', name, value); // Debug log
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDialogClose = () => {
    // setOpenDialog(false);
    setSelectedProduct(null);
  };

  // Helper function to format type
  const formatType = useCallback(() => 'Digital', []);

  // Fetch products
  const fetchProducts = useCallback(async () => {
    try {
      const response = await api.get('/products/');
      setProducts(response.data);
    } catch (err) {
      if (err.response?.status === 401) {
        // logout();
      } else {
        console.error('Error fetching products:', err);
      }
    }
  }, []);

  // Delete product
  const handleDelete = useCallback(async (productId) => {
    try {
      await api.delete(`/products/${productId}/`);
      setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
      handleCloseMenu();
    } catch (err) {
      if (err.response?.status === 401) {
        // logout();
      } else {
        console.error('Error deleting product:', err);
      }
    }
  }, [handleCloseMenu]);

  // Add/Edit product
  const handleSaveProduct = useCallback(async (productData) => {
    try {
      const dataToSend = {
        title: productData.title,
        description: productData.description,
        price: parseFloat(productData.price),
        picture_url: productData.picture_url,
        type: 'Digital',
        template: productData.template,
        payment_methods: productData.payment_methods,
        black_mode_name_enabled: productData.black_mode_name_enabled,
        black_mode_email_enabled: productData.black_mode_email_enabled,
        black_mode_cpf_enabled: productData.black_mode_cpf_enabled,
        black_mode_phone_enabled: productData.black_mode_phone_enabled,
        seller_email: productData.support_email,
        seller_product_name: productData.seller_name
      };

      console.log('Sending product data:', dataToSend);

      let response;
      if (selectedProduct?.id) {
        console.log('Updating product:', selectedProduct.id);
        response = await api.patch(`/products/${selectedProduct.id}/`, dataToSend);
      } else {
        console.log('Creating new product');
        response = await api.post('/products/', dataToSend);
      }

      console.log('API Response:', response.data);
      
      // Update the products list with the new data
      if (selectedProduct?.id) {
        setProducts(prevProducts => 
          prevProducts.map(p => p.id === selectedProduct.id ? { ...p, ...response.data } : p)
        );
      } else {
        setProducts(prevProducts => [...prevProducts, response.data]);
      }

      setSelectedProduct(null);
      handleDialogClose();
      
      // Refresh the products list to ensure we have the latest data
      fetchProducts();
    } catch (err) {
      console.error('Error saving product:', err.response?.data || err.message);
      if (err.response?.status === 401) {
        // logout();
      }
    }
  }, [selectedProduct, handleDialogClose, fetchProducts]);

  const handleMenuOpen = useCallback((event, product) => {
    setAnchorEl(event.currentTarget);
    setSelectedProduct(product);
    // setOpenDialog(false); // Ensure dialog is closed when opening menu
  }, []);

  const handleEdit = useCallback(() => {
    handleCloseMenu();
    navigate(`/products/${selectedProduct.id}/edit`);
  }, [handleCloseMenu, selectedProduct, navigate]);

  const handleAddNew = useCallback(() => {
    setSelectedProduct(null); 
    // setOpenDialog(true);
    navigate('/products/new');
  }, []);

  // Get icon based on product type
  const getTypeIcon = useCallback(() => {
    return <Package size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />;
  }, [theme.palette.mode]);

  // Get color based on product type
  const getTypeColor = useCallback(() => {
    return theme.palette.mode === 'dark' ? '#fff' : '#000';
  }, [theme.palette.mode]);

  const getCheckoutUrl = useCallback((productId) => {
    return `${window.location.origin}/checkout/${productId}`;
  }, []);

  const handleCopyCheckoutUrl = useCallback((productId) => {
    const checkoutUrl = getCheckoutUrl(productId);
    navigator.clipboard.writeText(checkoutUrl).then(() => {
      console.log('URL de checkout copiada para a área de transferência!');
    });
  }, [getCheckoutUrl]);

  const renderProductDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return (
      <Typography variant="body2" sx={{ fontFamily: '"Inter", sans-serif', color: 'text.secondary' }}>
        Criado em: {formattedDate}
      </Typography>
    );
  };

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  // Filter products based on search
  const filteredProducts = products.filter(product => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (product.title?.toLowerCase() || '').includes(searchTermLower) ||
      (product.description?.toLowerCase() || '').includes(searchTermLower)
    );
  });

  return (
    <Box sx={{ p: '30px', bgcolor: colors.background, minHeight: '100vh' }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            ...interFont,
            color: colors.text.primary,
            mb: 1,
            fontSize: '24px',
            fontWeight: 600
          }}
        >
          Produtos
        </Typography>
        <Typography sx={{ ...interFont, color: colors.text.secondary }}>
          Gerencie seus produtos digitais e serviços
        </Typography>
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <TextField
          placeholder="Buscar produtos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={20} color={colors.text.secondary} />
              </InputAdornment>
            ),
            sx: {
              ...interFont,
              height: '44px',
              borderRadius: '10px',
              backgroundColor: colors.background,
              '& input': {
                padding: '12px 12px 12px 0',
                color: colors.text.primary,
              },
            },
          }}
          sx={{
            flex: 1,
            '& .MuiOutlinedInput-root': {
              backgroundColor: colors.background,
              '& fieldset': {
                borderColor: colors.border,
              },
              '&:hover fieldset': {
                borderColor: colors.primary,
              },
              '&.Mui-focused fieldset': {
                borderColor: colors.primary,
              },
            },
          }}
        />
        <Button
          variant="contained"
          onClick={handleAddNew}
          startIcon={<Plus size={20} />}
          sx={{
            height: '44px',
            bgcolor: '#29ae60',
            color: '#fff',
            borderRadius: '10px',
            px: '16px',
            py: '6px',
            fontSize: '16px',
            '&:hover': {
              bgcolor: '#22c55e',
            },
            textTransform: 'none',
            fontWeight: 500,
            ...interFont,
          }}
        >
          Adicionar Produto
        </Button>
      </Box>

      <Paper 
        elevation={0}
        sx={{ 
          bgcolor: theme.palette.background.paper, 
          borderRadius: '10px', 
          border: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
          overflow: 'hidden',
          boxShadow: 'none',
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            bgcolor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          {/* Actions - centered for icon */}
          <Box sx={{ width: 60, pr: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Ações
            </Typography>
          </Box>

          {/* Package icon space in header */}
          <Box sx={{ width: 55 }} />

          {/* Product */}
          <Box sx={{ flex: 1, pr: 3, textAlign: 'left' }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Produto
            </Typography>
          </Box>

          {/* Link */}
          <Box sx={{ flex: 1, pr: 3, textAlign: 'left' }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Link
            </Typography>
          </Box>
          {/* Status - left aligned */}
          <Box sx={{ width: 80, display: 'flex', justifyContent: 'flex-start', pl: 1 }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Status
            </Typography>
          </Box>
        </Box>

        {filteredProducts
          .filter(product => 
            product.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((product, index) => (
            <Box key={product.id} sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              p: 2, 
              borderBottom: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
              bgcolor: theme.palette.background.paper,
              '&:hover': {
                bgcolor: theme.palette.action.hover,
              },
              textAlign: 'center'
            }}>
              {/* Actions */}
              <Box sx={{ width: 60, pr: 2 }}>
                <IconButton
                  onClick={(e) => handleMenuOpen(e, product)}
                  sx={{
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      bgcolor: `${colors.primary}15`,
                    },
                  }}
                >
                  <MoreVertical size={20} />
                </IconButton>
              </Box>

              {/* Product Icon */}
              <Box sx={{ width: 55, display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: `${theme.palette.primary.main}15`,
                  }}
                >
                  <Package size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />
                </Box>
              </Box>

              {/* Product Name and ID */}
              <Box sx={{ flex: 1, pr: 3, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 500, textAlign: 'left' }}>
                  {product.title}
                </Typography>
                {renderProductDate(product.created_at)}
              </Box>

              {/* Checkout Link */}
              <Box sx={{ flex: 1, pr: 3, textAlign: 'left' }}>
                <Typography
                  component={RouterLink}
                  to={getCheckoutUrl(product.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    ...interFont,
                    color: colors.primary,
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'block',
                    textAlign: 'left',
                    '&:hover': {
                      color: colors.primaryHover,
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {getCheckoutUrl(product.id)}
                </Typography>
              </Box>
              {/* Status */}
              <Box sx={{ width: 80, display: 'flex', justifyContent: 'flex-start', pl: 1 }}>
                <Toggle
                  checked={product.active}
                  onChange={() => handleToggleActive(product.id, product.active, product)}
                  size="small"
                />
              </Box>
            </Box>
          ))}
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          },
        }}
      >
        <MenuItem
          onClick={handleEdit}
          sx={{
            ...interFont,
            color: theme.palette.text.primary,
            '&:hover': { bgcolor: theme.palette.action.hover },
          }}
        >
          <Edit size={16} style={{ marginRight: '8px' }} />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(selectedProduct.id);
            handleCloseMenu();
          }}
          sx={{
            ...interFont,
            color: '#F04438',
            '&:hover': { bgcolor: '#FEF3F2' },
          }}
        >
          <Trash size={16} style={{ marginRight: '8px' }} />
          Excluir
        </MenuItem>
      </Menu>

      {/* <ProductForm
        open={openDialog}
        handleClose={handleDialogClose}
        handleSubmit={handleSaveProduct}
        initialData={selectedProduct}
      /> */}
    </Box>
  );
};

export default Products;
