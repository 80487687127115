import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Stack, Typography, ToggleButton, ToggleButtonGroup, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, IconButton, Button } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RefreshCcw, TrendingUp, Eye, DollarSign, Users, BarChart, ShoppingBag, Calendar, Clock, ChevronRight, Search, Download, Filter } from 'react-feather';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../services/api';
import { formatCurrency } from '../utils/format';
import { ReactComponent as CreditCardIcon } from '../assets/creditcard.svg';

// Font styles
const interFont = {
  fontFamily: '"Inter", sans-serif',
};

// Theme colors
const getThemeColors = (darkMode) => ({
  background: darkMode ? '#1A1D1F' : '#fdfdfd',
  text: {
    primary: darkMode ? '#fff' : '#1A1D1F',
    secondary: darkMode ? '#9A9FA5' : '#6F767E',
  }
});

const Dashboard = ({ darkMode }) => {
  const [chartData, setChartData] = useState({
    revenueData: [],
    loading: false,
    error: null
  });
  const [statsData, setStatsData] = useState({
    totalRevenue: 0,
    todaySales: 0,
    refundsTotal: 0,
    pixCount: 0,
    creditCardCount: 0,
    loading: true,
    error: null
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState('last15');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [customButtonPosition, setCustomButtonPosition] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const getStatusDisplay = (status) => {
    const statusMap = {
      'failed': 'Rejeitada',
      'pending': 'Pendente',
      'approved': 'Aprovada'
    };
    return statusMap[status] || status;
  };

  const getStatusColor = (status) => {
    if (status === 'failed') return '#FF6B6B';
    if (status === 'approved') return '#1CBD72';
    return '#FFA940';
  };

  const getStatusStyle = (status) => {
    const color = getStatusColor(status);
    return {
      backgroundColor: `${color}15`,
      color: color,
      fontWeight: 500,
      fontSize: '0.875rem',
      border: 'none'
    };
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'completed':
        return 'Concluído';
      case 'pending':
        return 'Pendente';
      case 'refunded':
        return 'Reembolsado';
      default:
        return status;
    }
  };

  const formatBirthDate = (dateString) => {
    if (!dateString) return 'N/A';
    // Split the date string and create date with UTC time
    const [year, month, day] = dateString.split('-').map(Number);
    const months = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    return `${String(day).padStart(2, '0')} de ${months[month - 1]} de ${year}`;
  };

  // Helper function to get current date range based on filter
  const getCurrentDateRange = () => {
    if (dateFilter === 'custom' && startDate && endDate) {
      return { start: startDate, end: endDate };
    }

    const end = new Date();
    end.setHours(23, 59, 59, 999);
    
    const start = new Date();
    if (dateFilter === 'last30') {
      start.setDate(start.getDate() - 30);
    } else { // last15 or default
      start.setDate(start.getDate() - 15);
    }
    start.setHours(0, 0, 0, 0);
    
    return { start, end };
  };

  // Separate effect for initial transactions load
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const { data: transactionsData } = await api.get('/transactions/');
        setTransactions(transactionsData);
      } catch (err) {
        console.error('Error fetching transactions:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, []); // Only run once on mount

  // Effect for stats data (30 days + today + transactions)
  useEffect(() => {
    const fetchStatsData = async () => {
      try {
        setStatsData(prev => ({ ...prev, loading: true, error: null }));

        // Fetch last 30 days stats and transactions
        const params = new URLSearchParams();
        const end = new Date();
        end.setHours(23, 59, 59, 999);
        const start = new Date();
        start.setDate(start.getDate() - 30);
        start.setHours(0, 0, 0, 0);
        
        params.append('start_date', start.toISOString().split('T')[0]);
        params.append('end_date', end.toISOString().split('T')[0]);

        // Add parameter to request today's sales
        const todayParams = new URLSearchParams();
        const today = new Date();
        todayParams.append('start_date', today.toISOString().split('T')[0]);
        todayParams.append('end_date', today.toISOString().split('T')[0]);

        // Fetch stats and transactions in parallel
        const [statsResponse, transactionsResponse, todayStatsResponse] = await Promise.all([
          api.get(`/dashboard/transactions/?${params.toString()}`),
          api.get('/transactions/'),
          api.get(`/dashboard/transactions/?${todayParams.toString()}`)
        ]);

        const { data: statsData } = statsResponse;
        const { data: transactionsData } = transactionsResponse;
        const { data: todayStatsData } = todayStatsResponse;

        // Calculate stats from transactions
        const approvedTransactions = transactionsData.filter(tx => tx.status === 'approved');
        const pixCount = approvedTransactions.filter(tx => tx.payment_method === 'pix').length;
        const creditCardCount = approvedTransactions.filter(tx => tx.payment_method === 'credit_card').length;

        setStatsData({
          totalRevenue: statsData.total_amount || 0,
          todaySales: todayStatsData.total_amount || 0,
          refundsTotal: statsData.refunds_total || 0,
          pixCount,
          creditCardCount,
          loading: false,
          error: null
        });

        setTransactions(transactionsData);
      } catch (err) {
        console.error('Error fetching stats data:', err);
        setStatsData(prev => ({ 
          ...prev, 
          loading: false, 
          error: 'Failed to load stats data' 
        }));
      }
    };

    fetchStatsData();
  }, []); // Only run once on mount

  // Effect for chart data
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        setChartData(prev => ({ ...prev, loading: true, error: null }));

        const params = new URLSearchParams();

        if (dateFilter === 'custom' && startDate && endDate) {
          params.append('start_date', startDate.toISOString().split('T')[0]);
          params.append('end_date', endDate.toISOString().split('T')[0]);
        } else if (dateFilter === 'last15') {
          const date = new Date();
          date.setDate(date.getDate() - 15);
          const startDate = new Date(date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          params.append('start_date', startDate.toISOString().split('T')[0]);
          params.append('end_date', endDate.toISOString().split('T')[0]);
        } else if (dateFilter === 'last30') {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          const startDate = new Date(date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          params.append('start_date', startDate.toISOString().split('T')[0]);
          params.append('end_date', endDate.toISOString().split('T')[0]);
        }

        const { data: chartData } = await api.get(`/dashboard/transactions/?${params.toString()}`);

        setChartData({
          revenueData: chartData.daily_totals?.map(item => ({
            name: item.date,
            revenue: item.total
          })) || [],
          loading: false,
          error: null
        });
      } catch (err) {
        console.error('Error fetching chart data:', err);
        setChartData(prev => ({
          ...prev,
          loading: false,
          error: 'Failed to load chart data'
        }));
      }
    };

    fetchChartData();
  }, [dateFilter, startDate, endDate]); // Only run when date filter changes

  const handleToggleChange = (event, newValue) => {
    if (!newValue) return; // Don't update if button is deselected
    
    if (newValue === 'custom') {
      // If already in custom mode, just open the picker
      const button = event.currentTarget;
      const rect = button.getBoundingClientRect();
      setCustomButtonPosition({
        top: rect.bottom + window.scrollY,
        left: rect.right - 200
      });
      
      // Set temp dates to current date range
      const { start, end } = getCurrentDateRange();
      setTempStartDate(start);
      setTempEndDate(end);
      setOpenDatePicker(true);
    } else {
      setDateFilter(newValue);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDatePickerClose = () => {
    setOpenDatePicker(false);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const handleDatePickerSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setDateFilter('custom');
      setOpenDatePicker(false);
    }
  };

  const handleOpenDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setDetailsModalOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsModalOpen(false);
    setSelectedTransaction(null);
  };

  const StatsCard = ({ title, value, subtitle, Icon, color }) => (
    <Box sx={{
      p: 3,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      bgcolor: darkMode ? '#1A1D1F' : '#fdfdfd',
      border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
      borderRadius: '10px',
    }}>
      <Stack spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: color,
            }}
          >
            <Icon size={20} />
          </Box>
          <Typography 
            variant="body1" 
            sx={{ 
              color: color,
              fontSize: '13px',
              fontWeight: 600,
              letterSpacing: '0.1px',
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: 1,
              letterSpacing: '-0.2px',
            }}
          >
            {value}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              color: darkMode ? '#9A9FA5' : '#6F767E',
              fontSize: '13px',
              mb: 0.3,
              fontWeight: 500,
              letterSpacing: '0.1px',
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        p: 3,
        height: '100%',
      }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (statsData.error || chartData.error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        p: 3,
        height: '100%',
      }}>
        <Typography color="error">{statsData.error || chartData.error}</Typography>
      </Box>
    );
  }

  const themeColors = getThemeColors(darkMode);

  return (
    <Box sx={{ 
      p: '30px',
      bgcolor: themeColors.background,
      minHeight: '100vh'
    }}>
      <Container 
        maxWidth="xl"
        disableGutters
        sx={{
          px: 0
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              color: themeColors.text.primary,
              fontSize: '24px',
              fontWeight: 600,
            }}
          >
            Métricas
          </Typography>
          <Typography sx={{ ...interFont, color: themeColors.text.secondary }}>
            Acompanhe suas vendas e métricas
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {/* Left side - Graph */}
          <Grid item xs={12} md={9}>
            <Box sx={{
              p: 3,
              height: '100%',
              minHeight: '410px', // Set minimum height to match cards column
              bgcolor: darkMode ? '#1A1D1F' : '#fdfdfd',
              border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: themeColors.text.primary,
                    fontSize: '1.1rem',
                    fontWeight: 600,
                  }}
                >
                  Faturamento
                </Typography>
                <ToggleButtonGroup
                  value={dateFilter}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Filtro de Data"
                  size="small"
                  sx={{
                    '& .MuiToggleButton-root': {
                      '&.Mui-selected': {
                        backgroundColor: '#29AE60',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#1f8b4c',
                        },
                      },
                      '&:hover': {
                        backgroundColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
                      },
                    },
                  }}
                >
                  <ToggleButton value="last15" aria-label="Últimos 15 dias">
                    15 dias
                  </ToggleButton>
                  <ToggleButton value="last30" aria-label="Últimos 30 dias">
                    30 dias
                  </ToggleButton>
                  <ToggleButton 
                    value="custom" 
                    aria-label="Personalizado"
                    onClick={(e) => {
                      // Always open date picker when clicking, even if already selected
                      const rect = e.currentTarget.getBoundingClientRect();
                      setCustomButtonPosition({
                        top: rect.bottom + window.scrollY,
                        left: rect.right - 200
                      });
                      
                      // Set temp dates to current date range
                      const { start, end } = getCurrentDateRange();
                      setTempStartDate(start);
                      setTempEndDate(end);
                      setOpenDatePicker(true);
                    }}
                  >
                    Personalizado
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box sx={{ flex: 1, width: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  {chartData.loading ? (
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center',
                      height: '100%',
                      color: themeColors.text.secondary
                    }}>
                      Carregando...
                    </Box>
                  ) : (
                    <LineChart
                      data={chartData.revenueData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid 
                        strokeDasharray="3 3" 
                        stroke={darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'} 
                        vertical={false}
                      />
                      <XAxis 
                        dataKey="name" 
                        stroke={themeColors.text.secondary}
                        fontSize={12}
                        tickLine={false}
                        axisLine={{ stroke: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)' }}
                        interval={dateFilter === 'custom' ? Math.floor(chartData.revenueData.length / 10) : dateFilter === 'last30' ? 1 : 0}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                      />
                      <YAxis 
                        stroke={themeColors.text.secondary}
                        fontSize={12}
                        tickLine={false}
                        axisLine={{ stroke: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)' }}
                        tickFormatter={(value) => `R$${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}
                      />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: darkMode ? '#1A1D1F' : '#fff',
                          border: '1px solid',
                          borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                          borderRadius: '8px',
                          padding: '12px',
                          boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
                        }}
                        labelStyle={{ 
                          color: themeColors.text.secondary,
                          fontSize: '12px',
                          fontWeight: 500,
                        }}
                        formatter={(value) => [`R$${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`, 'Faturamento']}
                      />
                      <Line
                        type="monotone"
                        dataKey="revenue"
                        stroke="#27AE60"
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 4, fill: "#27AE60" }}
                      />
                    </LineChart>
                  )}
                </ResponsiveContainer>
              </Box>
            </Box>
          </Grid>

          {/* Right side - Metrics Column */}
          <Grid item xs={12} md={3}>
            <Stack spacing={3} sx={{ height: '100%' }}>
              {/* Revenue Card */}
              <Box sx={{
                py: 2.5,
                px: 2.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#fdfdfd',
                border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                borderRadius: '10px',
                height: '120px', // Fixed height for consistent sizing
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      bgcolor: '#E6F7D9',
                      mr: 2,
                    }}
                  >
                    <Filter size={24} color="#1CBD72" />
                    </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: themeColors.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}>
                      Vendas últimos 30 dias
                    </Typography>
                    <Typography variant="h6" sx={{ color: themeColors.text.primary, fontSize: '1.25rem', mt: 0.5 }}>
                      {formatCurrency(statsData.totalRevenue)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Monthly Revenue Card */}
              <Box sx={{
                py: 2.5,
                px: 2.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#fdfdfd',
                border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                borderRadius: '10px',
                height: '120px', // Fixed height for consistent sizing
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      bgcolor: '#E6F7D9',
                      mr: 2,
                    }}
                  >
                    <BarChart size={24} color="#1CBD72" />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: themeColors.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}>
                      Vendas hoje
                    </Typography>
                    <Typography variant="h6" sx={{ color: themeColors.text.primary, fontSize: '1.25rem', mt: 0.5 }}>
                      {formatCurrency(statsData.todaySales)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Refunds Card */}
              <Box sx={{
                py: 2.5,
                px: 2.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#fdfdfd',
                border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                borderRadius: '10px',
                height: '120px', // Fixed height for consistent sizing
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: themeColors.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}>
                      Pagamentos aprovados
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {/* PIX Bar */}
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box
                          sx={{
                            height: '24px',
                            width: statsData.pixCount > 0 ? `${(statsData.pixCount / (statsData.pixCount + statsData.creditCardCount) * 100)}%` : '0%',
                            bgcolor: '#29AE60',
                            borderRadius: '4px',
                            mr: 1
                          }}
                        />
                        <img src="/pixiconblackdash.svg" alt="PIX" style={{ width: '20px', height: '20px' }} />
                        <Typography variant="body2" sx={{ ml: 1, color: themeColors.text.secondary }}>
                          ({statsData.pixCount || 0})
                        </Typography>
                      </Box>
                      {/* Credit Card Bar */}
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            height: '24px',
                            width: statsData.creditCardCount > 0 ? `${(statsData.creditCardCount / (statsData.pixCount + statsData.creditCardCount) * 100)}%` : '0%',
                            bgcolor: '#4abe7b',
                            borderRadius: '4px',
                            mr: 1
                          }}
                        />
                        <CreditCardIcon style={{ width: '20px', height: '20px' }} />
                        <Typography variant="body2" sx={{ ml: 1, color: themeColors.text.secondary }}>
                          ({statsData.creditCardCount || 0})
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        {/* Transactions List */}
        <Box sx={{ mt: 4 }}>
          <Box sx={{
            p: 3,
            bgcolor: darkMode ? '#1A1D1F' : '#fdfdfd',
            border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
            borderRadius: '10px',
          }}>
            <Typography
              variant="h6"
              sx={{
                color: themeColors.text.primary,
                mb: 3,
                fontSize: '1.1rem',
                fontWeight: 600,
              }}
            >
              Transações Recentes
            </Typography>
            
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Data
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Cliente
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Produto
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Valor
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Método
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Detalhes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {new Date(transaction.created_at).toLocaleDateString('pt-BR')} {new Date(transaction.created_at).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {transaction.customer_name || 'N/A'}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {transaction.product.title}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {formatCurrency(transaction.amount)}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {transaction.payment_method === 'credit_card' ? 'Cartão de Crédito' : 'Pix'}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        <Chip 
                          label={getStatusDisplay(transaction.status)}
                          sx={getStatusStyle(transaction.status)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleOpenDetails(transaction)}
                          sx={{ color: themeColors.text.secondary }}
                        >
                          <Eye size={18} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {/* Date Picker Modal */}
        <Modal
          open={openDatePicker}
          onClose={handleDatePickerClose}
          aria-labelledby="date-range-picker"
          BackdropProps={{ invisible: true }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 300,
              p: 2,
              top: customButtonPosition?.top || 0,
              left: customButtonPosition?.left || 0,
              bgcolor: darkMode ? '#1A1D1F' : '#fff',
              color: darkMode ? '#fff' : '#000',
              border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
              borderRadius: '10px',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={2}>
                <DesktopDatePicker
                  label="Data inicial"
                  value={tempStartDate}
                  onChange={(newValue) => setTempStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      fullWidth
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
                          },
                        },
                      }}
                    />
                  )}
                />
                <DesktopDatePicker
                  label="Data final"
                  value={tempEndDate}
                  onChange={(newValue) => setTempEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      fullWidth
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
                          },
                        },
                      }}
                    />
                  )}
                />
                <form onSubmit={handleDatePickerSubmit}>
                  <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
                    <Button variant="outlined" onClick={handleDatePickerClose} size="small">
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleDatePickerSubmit}
                      variant="contained"
                      sx={{
                        bgcolor: '#29AE60',
                        color: '#fff',
                        '&:hover': {
                          bgcolor: '#1f8b4c',
                        },
                      }}
                    >
                      Aplicar
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </LocalizationProvider>
          </Box>
        </Modal>

        {/* Transaction Details Modal */}
        <Modal
          open={detailsModalOpen}
          onClose={handleCloseDetails}
          aria-labelledby="transaction-details-modal"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '90vh',
            overflow: 'auto'
          }}>
            {selectedTransaction && (
              <>
                <Typography variant="h6" component="h2" gutterBottom>
                  Detalhes da Transação
                </Typography>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">ID da Transação</Typography>
                    <Typography>{selectedTransaction.payment_id || 'N/A'}</Typography>
                  </Box>
                  
                  {/* Customer Information */}
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                      Informações do Cliente
                    </Typography>
                    <Stack spacing={1}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Nome</Typography>
                        <Typography>{selectedTransaction.customer_name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Email</Typography>
                        <Typography>{selectedTransaction.customer_email}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Telefone</Typography>
                        <Typography>{selectedTransaction.customer_phone}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">CPF</Typography>
                        <Typography>{selectedTransaction.customer_cpf}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Data de Nascimento</Typography>
                        <Typography>
                          {formatBirthDate(selectedTransaction.customer_birth_date)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* Payment Information */}
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                      Informações do Pagamento
                    </Typography>
                    <Stack spacing={2}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Produto</Typography>
                        <Typography>{selectedTransaction.product.title}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Valor</Typography>
                        <Typography>{formatCurrency(selectedTransaction.amount)}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                        <Chip 
                          label={getStatusDisplay(selectedTransaction.status)}
                          sx={getStatusStyle(selectedTransaction.status)}
                          size="small"
                        />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Data de Criação</Typography>
                        <Typography>{new Date(selectedTransaction.created_at).toLocaleString('pt-BR')}</Typography>
                      </Box>
                      {selectedTransaction.paidTimestamp && (
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">Data de Pagamento</Typography>
                          <Typography>{new Date(selectedTransaction.paidTimestamp).toLocaleString('pt-BR')}</Typography>
                        </Box>
                      )}
                    </Stack>
                  </Box>

                  {/* Transaction Requests */}
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                      Requisições da Transação
                    </Typography>
                    {selectedTransaction.requests?.length > 0 ? (
                      <Stack spacing={2}>
                        {selectedTransaction.requests.map((request, index) => (
                          <Box
                            key={index}
                            sx={{
                              p: 2,
                              bgcolor: darkMode ? 'grey.900' : 'grey.50',
                              borderRadius: 1,
                            }}
                          >
                            <Stack spacing={1}>
                              <Box>
                                <Typography variant="subtitle2" color="text.secondary">URL</Typography>
                                <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                  {request.request_url}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                                <Typography variant="body2">
                                  {request.response_status}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="subtitle2" color="text.secondary">Data</Typography>
                                <Typography variant="body2">
                                  {new Date(request.created_at).toLocaleString('pt-BR')}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="subtitle2" color="text.secondary">Request Body</Typography>
                                <Box sx={{ 
                                  bgcolor: darkMode ? 'grey.800' : 'grey.100',
                                  p: 1,
                                  borderRadius: 1,
                                  mt: 0.5
                                }}>
                                  <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '0.75rem' }}>
                                    {JSON.stringify(request.request_body, null, 2)}
                                  </pre>
                                </Box>
                              </Box>
                              <Box>
                                <Typography variant="subtitle2" color="text.secondary">Response Body</Typography>
                                <Box sx={{ 
                                  bgcolor: darkMode ? 'grey.800' : 'grey.100',
                                  p: 1,
                                  borderRadius: 1,
                                  mt: 0.5
                                }}>
                                  <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '0.75rem' }}>
                                    {JSON.stringify(request.response_body, null, 2)}
                                  </pre>
                                </Box>
                              </Box>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        Nenhuma requisição encontrada
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default Dashboard;
